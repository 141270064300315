import "../css/app.css";
import React from "react";
import Api from "../../apis/api";
import Utils from "../Utils";
import { uploadFile } from "react-s3";
import { API_ROOT } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Button from "../common/Button";
import TextareaAutosize from "react-autosize-textarea";
import iconeSpin from "../../assets/images/icone_spin.png";
import { toast } from "react-toastify";
import InputField from "../inputs/InputField";
import { HiPencilSquare, HiTrash } from "react-icons/hi2";
import DiscountInput from "../inputs/DiscountInput";

class PlanItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: false,
      showDelete: false,
      id: "",
      price: "",
      spinsCount: "",
      // Campos de edição
      inputPrice: "",
      inputSpinsCount: "",
      inputDiscount: "",
      // Campos calculados
      inputDiscountValue: "",
      inputCalculatedValue: "",
      inputSpinValue: "",
      inputSaleValue: "",
    };
  }

  componentDidMount = () => {
    const { item } = this.props;
    console.log("dados item", item);
    this.setState(
      {
        id: item.id,
        price: item.price,
        spinsCount: item.spins_count,
        inputPrice: item.price,
        inputSpinsCount: item.spins_count,
        inputDiscount: item.discount,
        inputSaleValue: item.sale_price,
      },
      this.updateCalculations
    );
  };

  // Função para atualizar os valores calculados no modal de edição
  updateCalculations = () => {
    let price =
      parseFloat(this.state.inputPrice.toString().replace(",", ".")) || 0;
    let spinsCount = parseInt(this.state.inputSpinsCount, 10) || 0;
    let discountPercent =
      parseFloat(this.state.inputDiscount.toString().replace(",", ".")) || 0;

    if (price <= 0 || spinsCount <= 0) {
      this.setState({
        inputDiscountValue: "",
        inputCalculatedValue: "",
        inputSpinValue: "",
        inputSaleValue: "",
      });
      return;
    }

    let discountValue = (price * discountPercent) / 100;
    let calculatedValue = price - discountValue;
    let spinValue =
      spinsCount > 0 ? (calculatedValue / spinsCount).toFixed(2) : 0;
    let saleValue = calculatedValue;

    this.setState({
      inputDiscountValue: discountValue.toFixed(2),
      inputCalculatedValue: calculatedValue.toFixed(2),
      inputSpinValue: spinValue,
      inputSaleValue: saleValue.toFixed(2),
    });
  };

  calculateValues = () => {
    const { inputPrice, inputSpinsCount, inputDiscount } = this.state;
    const price = parseFloat(inputPrice) || 0;
    const spins = parseInt(inputSpinsCount, 10) || 0;
    const discountPercent = parseFloat(inputDiscount) || 0;
    const discountValue = (price * discountPercent) / 100;
    const calculatedPrice = price - discountValue;
    const unitSpinPrice = spins > 0 ? calculatedPrice / spins : 0;
    const salePrice = calculatedPrice;

    return { discountValue, calculatedPrice, unitSpinPrice, salePrice };
  };

  render() {
    const { price, spinsCount, inputDiscount } = this.state;
    const { discountValue, calculatedPrice, unitSpinPrice, salePrice } =
      this.calculateValues();
    const { index } = this.props;

    return (
      <div>
        {/* Linha da tabela */}
        <table
          style={{
            width: "100%",
            paddingLeft: "20px",
            paddingRight: "20px",
            border: "1px solid #eae4e4",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            height: "35px",
            backgroundColor: index % 2 === 0 ? "#fff" : "#F9F9F9",
          }}
        >
          <tbody
            style={{
              textAlign: "left",
            }}
          >
            <tr>
              <td style={{ width: "10%" }}>
                <p style={{}} className="name">
                  {spinsCount}
                </p>
              </td>
              <td style={{ width: "10%" }}>
                <p className="name">R$ {price}</p>
              </td>
              <td style={{ width: "10%" }}>
                <p className="name">{inputDiscount}%</p>
              </td>
              <td style={{ width: "10%" }}>
                <p className="name">R$ {discountValue.toFixed(2)}</p>
              </td>
              <td style={{ width: "10%" }}>
                <p className="name">R$ {calculatedPrice.toFixed(2)}</p>
              </td>
              <td style={{ width: "10%" }}>
                <p className="name">R$ {unitSpinPrice.toFixed(2)}</p>
              </td>
              <td style={{ width: "10%" }}>
                <p className="name">R$ {salePrice.toFixed(2)}</p>
              </td>
              <td style={{ width: "10%" }}>
                <div
                  className="actions"
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div
                    className="edit-icon"
                    onClick={this.handleShowEdit}
                    style={{ cursor: "pointer" }}
                  >
                    <HiPencilSquare
                      style={{ fontSize: "24px" }}
                      color="#ACACAC"
                      icon={"edit"}
                    />
                  </div>
                  <div
                    className="edit-icon"
                    onClick={this.handleShowDelete}
                    style={{ cursor: "pointer" }}
                  >
                    <HiTrash
                      style={{ fontSize: "24px" }}
                      color="#ACACAC"
                      icon={"trash-alt"}
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Modal de Edição */}
        <Modal
          show={this.state.showEdit}
          onHide={this.handleCloseEdit}
          backdrop="static"
          className="modal"
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title className="modal-title">Editar Plano</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "16px",
                  flexWrap: "nowrap",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: "1" }}>
                  <InputField
                    label="Quantidade (*)"
                    value={this.state.inputSpinsCount}
                    onChange={(e) =>
                      this.setState(
                        { inputSpinsCount: e.target.value },
                        this.updateCalculations
                      )
                    }
                    placeholder="Digite a quantidade de spins..."
                  />
                </div>
                <div style={{ flex: "1" }}>
                  <InputField
                    label="Valor base (*)"
                    value={this.state.inputPrice}
                    onChange={(e) =>
                      this.setState(
                        { inputPrice: e.target.value },
                        this.updateCalculations
                      )
                    }
                    placeholder="Digite o valor base..."
                  />
                </div>
                <div style={{ flex: "1" }}>
                  <DiscountInput
                    label="Desconto (%)"
                    value={this.state.inputDiscount}
                    onChange={(e) =>
                      this.setState(
                        { inputDiscount: e.target.value },
                        this.updateCalculations
                      )
                    }
                    placeholder="Digite o desconto em %..."
                    tag={"%"}
                  />
                </div>
              </div>

              {/* Linha com os valores calculados */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "16px",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  marginTop: "16px",
                }}
              >
                <div style={{ flex: "1" }}>
                  <DiscountInput
                    label="Desconto (R$)"
                    value={this.state.inputDiscountValue}
                    readOnly
                    tag={"R$"}
                  />
                </div>
                <div style={{ flex: "1" }}>
                  <InputField
                    label="Valor calculado"
                    value={this.state.inputCalculatedValue}
                    readOnly
                  />
                </div>
                <div style={{ flex: "1" }}>
                  <InputField
                    label="Valor un. Spin"
                    value={this.state.inputSpinValue}
                    readOnly
                  />
                </div>
              </div>

              <div style={{ flex: "1", marginTop: "14px" }}>
                <InputField
                  label="Valor de venda"
                  value={this.state.inputSaleValue}
                  readOnly
                />
              </div>
            </>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.handleSave} type="orange" title="Salvar" />
          </Modal.Footer>
        </Modal>

        {/* Modal de Exclusão */}
        <Modal
          show={this.state.showDelete}
          onHide={this.handleCloseDelete}
          backdrop="static"
          className="modal"
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title className="modal-title">Deletar Plano</Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ minHeight: "100px", textAlign: "center" }}>
            <h3>
              O item deletado não poderá ser recuperado. Você deseja continuar?
            </h3>
          </Modal.Body>

          <Modal.Footer>
            <Button
              style={{ width: "100px" }}
              onClick={this.handleDelete}
              type="orange"
              title="Sim"
            />
            <Button
              style={{ width: "100px" }}
              onClick={this.handleCloseDelete}
              title="Não"
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  // Handlers de modais
  handleCloseEdit = () => {
    this.setState({ showEdit: false });
  };

  handleShowEdit = () => {
    // Ao abrir o modal, garante que os inputs de edição estão com os valores atuais
    this.setState(
      {
        inputPrice: this.state.price,
        inputSpinsCount: this.state.spinsCount,
        inputDiscount: this.state.inputDiscount,
      },
      () => {
        this.updateCalculations();
        this.setState({ showEdit: true });
      }
    );
  };

  handleCloseDelete = () => {
    this.setState({ showDelete: false });
  };

  handleShowDelete = () => {
    this.setState({ showDelete: true });
  };

  handleDelete = () => {
    this.props.onPlanDelete(this.state.id);
    this.handleCloseDelete();
  };

  handleSave = () => {
    let price = Number(this.state.inputPrice.toString().replace(",", "."));
    let spinsCount = this.state.inputSpinsCount;
    let discount = Number(
      this.state.inputDiscount.toString().replace(",", ".")
    );

    if (!this.validateRequiredFields(price, spinsCount, discount)) {
      return;
    }

    this.setState({ showEdit: false });
    this.props.onSaveChanges(
      this.state.id,
      this.state.inputPrice,
      this.state.inputSpinsCount,
      this.state.inputDiscount,
      this.state.inputSaleValue
    );
  };

  validateRequiredFields(price, spinsCount, discount) {
    let requiredFieldsOK = true;
    if (
      price === "" ||
      price === 0 ||
      spinsCount === "" ||
      spinsCount === 0 ||
      isNaN(price) ||
      isNaN(spinsCount) ||
      Number(price) > 99999 ||
      Number(spinsCount) > 99999 ||
      isNaN(discount)
    ) {
      toast.info(
        "Verifique os campos obrigatórios com (*) para poder salvar a edição do plano.",
        {
          className: "toast-info",
        }
      );
      requiredFieldsOK = false;
    }
    return requiredFieldsOK;
  }
}

export default PlanItem;
