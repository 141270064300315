import React from "react";

const InputField = ({ label, value, onChange, placeholder, type = "text" }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {label && (
        <label
          style={{
            display: "block",
            fontSize: "16px",
            fontWeight: "500",
            color: "#000",
            marginBottom: "4px",
          }}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          display: "block",
          width: "100%",
          height: "40px",
          padding: "8px 12px",
          borderRadius: "15px",
          border: "1px solid #E3E3E3",
          backgroundColor: "#FAFAFA",
          color: "#1F2937",
          fontSize: "14px",
          outline: "none",
          transition: "border-color 0.2s ease-in-out",
        }}
      />
    </div>
  );
};

export default InputField;
