import "../css/app.css";
import React from "react";
import Api from "../../apis/api";
import Utils from "../Utils";
import { uploadFile } from "react-s3";
import { API_ROOT } from "../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

import Button from "../common/Button";

class SponsorItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: false,
      showDelete: false,

      id: "",
      image: "",
      name: "",
      link: "",

      inputImage: "",
      inputName: "",
      inputLink: "",
    };
  }

  componentDidMount = (props) => {
    const { item } = this.props;

    this.setState({
      id: item.id,

      image: item.image,
      name: item.name,
      link: item.link,

      inputImage: item.image,
      inputName: item.name,
      inputLink: item.link,
    });
  };

  render() {
    const { image, name } = this.state;

    return (
      <div className="sponsor-item">
        <div
          className="avatar-container"
          style={{ backgroundImage: `url(${image})` }}
        />
        <h3 className="name">{name}</h3>
        <div className="actions">
          <div className="edit-icon" onClick={this.handleShowEdit}>
            <FontAwesomeIcon icon={"cog"} />
          </div>
          <div className="edit-icon" onClick={this.handleShowDelete}>
            <FontAwesomeIcon icon={"trash"} />
          </div>
        </div>

        <Modal
          show={this.state.showEdit}
          onHide={this.handleCloseEdit}
          backdrop="static"
          className="modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">
              Editar Patrocinador
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              className="modal-avatar-container"
              style={{ backgroundImage: `url(${this.state.inputImage})` }}
            >
              <input
                type="file"
                name="edit-avatar"
                id="edit-avatar"
                onChange={this.handleSelectedFile}
              />
              <label htmlFor="edit-avatar">BUSCAR</label>
            </div>
            <div className="modal-card-data">
              <div className="modal-input-group modal-input-name">
                <label>Nome (*)</label>
                <input
                  type="text"
                  value={this.state.inputName}
                  onChange={(e) => this.setState({ inputName: e.target.value })}
                  placeholder="digite aqui o nome..."
                />
              </div>
              <div className="modal-input-group modal-input-name">
                <label>link</label>
                <input
                  type="text"
                  value={this.state.inputLink}
                  onChange={(e) => this.setState({ inputLink: e.target.value })}
                  placeholder="digite aqui o link..."
                />
              </div>
            </div>
          </Modal.Body>

          <div style={{ textAlign: "center" }}>
            <label className="style-button" htmlFor="insert-picture">
              <FontAwesomeIcon icon="image" /> obs.: imagem (proporção 1:1,
              largura máxima 600px, tipo jpg ou png)
            </label>
          </div>

          <Modal.Footer>
            <Button onClick={this.saveChanges} type="orange" title="Salvar" />
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showDelete}
          onHide={this.handleCloseDelete}
          backdrop="static"
          className="modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">
              Deletar Patrocinador
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ minHeight: "100px", textAlign: "center" }}>
            <h3>
              O item deletado não poderá ser recuperado. Você deseja continuar?
            </h3>
          </Modal.Body>

          <Modal.Footer>
            <Button
              style={{ width: "100px" }}
              onClick={this.handleDelete}
              type="orange"
              title="Sim"
            />
            <Button
              style={{ width: "100px" }}
              onClick={this.handleCloseDelete}
              title="Não"
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  // Handlers
  handleCloseEdit = () => {
    this.setState({
      showEdit: false,
    });
  };

  handleShowEdit = () => {
    this.setState(
      {
        inputName: this.state.name,
        inputLink: this.state.link,
        inputImage: this.state.image,
      },
      () => this.setState({ showEdit: true })
    );
  };

  handleCloseDelete = () => {
    this.setState({ showDelete: false });
  };

  handleShowDelete = () => {
    this.setState({ showDelete: true });
  };

  handleDelete = () => {
    this.props.onSponsorDelete(this.state.id);
    this.handleCloseDelete();
  };

  handleSelectedFile = (event) => {
    let file = event.target.files[0];
    let config = {
      bucketName: "mri-ead",
      bucketRoot: "https://s3-sa-east-1.amazonaws.com/mri-ead/",
      region: "sa-east-1",
      accessKeyId: "AKIAIBBX5O7CKQWW22HA",
      secretAccessKey: "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",
      dirName: "images",
    };

    // TODO: LOADER SPINNER
    uploadFile(file, config, API_ROOT).then(
      (data) => {
        this.setState({ inputImage: data.location });
        toast.success("Imagem enviada com sucesso!", {
          className: "toast-success",
        });
      },
      function () {
        toast.error(`Erro subindo a imagem! Tente novamente!`, {
          className: "toast-error",
        });
      }
    );
  };

  // checkForErrors(element) {
  //     if (element.error_code !== 0) {
  //         this.setState({ error: element.error_code });

  //         toast.error(`Erro executando esta operação! Código de erro: ${element.error_code}`, {
  //             className: 'toast-error'
  //         });

  //         return true;
  //     }

  //     return false;
  // };

  // Save
  saveChanges = async () => {
    let id = this.state.id;
    let name = this.state.inputName;
    let link = this.state.inputLink;
    let image = this.state.inputImage;

    // Validações dos campos obrigatórios para salvar
    if (!this.validateRequiredFields(name, link)) {
      return;
    }

    let upload = await Api.sponsorPanelEditor(id, name, link, image);

    upload = upload.sponsors;
    if (Utils.checkForErrors(this, upload)) {
      return;
    }

    upload = upload.data.panel_editor;
    if (Utils.checkForErrors(this, upload)) {
      return;
    }

    upload = upload.data;
    if (Utils.checkForErrors(this, upload)) {
      return;
    } else {
      this.setState(
        {
          image: image,
          name: Utils.capitalizeFirstLetter(name),
          link: link,
        },
        () => {
          toast.success("Patrocinador cadastrado com sucesso!", {
            className: "toast-success",
          });

          this.setState({ showEdit: false });
        }
      );
    }
  };

  validateRequiredFields(name, link) {
    let requiredFieldsOK = true;
    if (name == "" || (link != "" && !link.includes("https"))) {
      toast.info(
        "Verifique os campos obrigatórios com (*) e se o link possue https, para poder salvar a edição do patrocinador.",
        {
          className: "toast-info",
        }
      );

      requiredFieldsOK = false;
    }

    return requiredFieldsOK;
  }
}

export default SponsorItem;
