import React from "react";
import Api from "../apis/api";
import Utils from "../components/Utils";
import { uploadFile } from "react-s3";
import { API_ROOT } from "../config";
import { X } from "lucide-react";
import Modal from "react-bootstrap/Modal";
import Button from "../components/common/Button";
import UserItem from "../components/js/UserItem";
import TextareaAutosize from "react-autosize-textarea";
import {
  ChevronFirst,
  ChevronLast,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { toast } from "react-toastify";
import { Row } from "react-bootstrap";

// ?sdf
class ScreenUsersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      findName: "",
      spinStart: "",
      spinEnd: "",
      users: [],
      page: 1,
      pagination: {
        page: 1,
        pages: 1,
        pageSize: 50,
        total: 0,
      },
      error: 0,
      numberOfPages: undefined,
      buttonShowMore: true,
    };
  }

  componentDidMount() {
    this.fetchData(1, this.state.pagination.pageSize);
  }

  fetchData() {
    this.setState({ loading: true }, async () => {
      let spinStart = this.state.spinStart || 0;
      let spinEnd = this.state.spinEnd || 0;

      try {
        let response = await Api.userList(
          1,
          50,
          this.state.findName,
          spinStart,
          spinEnd
        );

        if (!response || !response.users || !response.users.list) {
          console.error("❌ Erro: Resposta da API inválida.");
          this.setState({ loading: false });
          return;
        }

        let allUsers = response.users.list || [];
        let totalUsers = allUsers.length;

        const displayPageSize = 10;
        let totalPages = Math.ceil(totalUsers / displayPageSize);

        this.setState({
          users: allUsers,
          pagination: {
            page: 1,
            pages: totalPages,
            pageSize: displayPageSize,
            total: totalUsers,
          },
          loading: false,
        });
      } catch (error) {
        console.error("❌ Erro ao buscar usuários:", error);
        this.setState({ loading: false });
      }
    });
  }

  handleFilter = () => {
    this.setState({ users: [], page: 1 }, () => {
      this.fetchData(1, this.state.pagination.pageSize);
    });
  };

  renderList() {
    const { users, pagination } = this.state;
    const { page, pageSize } = pagination;

    if (users.length === 0) {
      return (
        <tr>
          <td colSpan="6" style={{ textAlign: "center", padding: "1rem" }}>
            Não foi encontrado nenhum resultado.
          </td>
        </tr>
      );
    }

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentUsers = users.slice(startIndex, endIndex);

    return currentUsers.map((user, index) => (
      <UserItem
        onSaveChanges={this.saveChanges}
        key={user.id}
        item={user}
        index={startIndex + index}
      />
    ));
  }

  handlePreviousPage = () => {
    const { page } = this.state.pagination;
    if (page > 1) {
      console.log(`⬅️ Indo para a página ${page - 1}`);
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: page - 1 },
      }));
    }
  };

  handleNextPage = () => {
    const { page, pages } = this.state.pagination;
    if (page < pages) {
      console.log(`➡️ Indo para a página ${page + 1}`);
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: page + 1 },
      }));
    }
  };

  handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    const total = this.state.pagination.total;
    const newPages = Math.ceil(total / newPageSize);

    this.setState((prevState) => ({
      pagination: {
        ...prevState.pagination,
        pageSize: newPageSize,
        pages: newPages,
        page: 1,
      },
    }));
  };

  renderPagination() {
    const { page, pages, pageSize, total } = this.state.pagination;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "20px",
        }}
      >
        {/* Seletor de linhas por página */}
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "30px" }}
        >
          <span>Linhas por página: </span>
          <select
            value={pageSize}
            onChange={this.handlePageSizeChange}
            style={{ margin: "0 10px" }}
          >
            {[10, 15, 20, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div style={{ marginRight: "30px" }}>
          <span>
            {(page - 1) * pageSize + 1} - {Math.min(page * pageSize, total)} de{" "}
            {total}
          </span>
        </div>

        <button
          onClick={() =>
            this.setState({ pagination: { ...this.state.pagination, page: 1 } })
          }
          disabled={page === 1}
          style={{
            marginLeft: "10px",
            background: "none",
            border: "none",
            cursor: page === 1 ? "default" : "pointer",
          }}
        >
          <ChevronFirst
            style={{ fontSize: "20px", color: page === 1 ? "#ACACAC" : "#000" }}
          />
        </button>

        <button
          onClick={this.handlePreviousPage}
          disabled={page === 1}
          style={{
            marginLeft: "5px",
            background: "none",
            border: "none",
            cursor: page === 1 ? "default" : "pointer",
          }}
        >
          <ChevronLeft
            style={{ fontSize: "20px", color: page === 1 ? "#ACACAC" : "#000" }}
          />
        </button>

        <button
          onClick={this.handleNextPage}
          disabled={page === pages}
          style={{
            marginLeft: "5px",
            background: "none",
            border: "none",
            cursor: page === pages ? "default" : "pointer",
          }}
        >
          <ChevronRight
            style={{
              fontSize: "20px",
              color: page === pages ? "#ACACAC" : "#000",
            }}
          />
        </button>

        <button
          onClick={() =>
            this.setState({
              pagination: { ...this.state.pagination, page: pages },
            })
          }
          disabled={page === pages}
          style={{
            marginLeft: "5px",
            background: "none",
            border: "none",
            cursor: page === pages ? "default" : "pointer",
          }}
        >
          <ChevronLast
            style={{
              fontSize: "20px",
              color: page === pages ? "#ACACAC" : "#000",
            }}
          />
        </button>
      </div>
    );
  }

  render() {
    if (this.state.error !== 0) {
      return null;
    }

    return (
      <div
        className="main-content"
        style={{
          textAlign: "center",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <div
          className="content-header fluid centered flex-between min-600"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "2rem",
            paddingTop: "1rem",
          }}
        >
          <span
            style={{ fontSize: "2rem", fontWeight: "bold", color: "#FF8F18" }}
          >
            Usuários
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#fff",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            borderRadius: "10px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>&nbsp;</div>

            <span
              style={{
                fontWeight: "bold",
                fontSize: "1.4rem",
                marginRight: "1rem",
                whiteSpace: "nowrap",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              Filtrar usuários
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <label
              htmlFor="buscar-nome"
              style={{
                color: "#FF8F18",
                fontWeight: "500",
                marginBottom: "0.5rem",
              }}
            >
              Nome
            </label>

            <div style={{ position: "relative", display: "inline-block" }}>
              <input
                id="buscar-nome"
                type="text"
                placeholder="Buscar por nome"
                value={this.state.findName}
                onChange={(e) => this.setState({ findName: e.target.value })}
                style={{
                  padding: "0.5rem",
                  paddingRight: "30px",
                  borderRadius: "15px",
                  border: "1px solid #E3E3E3",
                  marginRight: "20px",
                  width: "300px",
                  height: "40px",
                  backgroundColor: "#FAFAFA",
                }}
              />
              {this.state.findName && (
                <X
                  onClick={() => {
                    this.setState({ findName: "", users: [], page: 1 }, () => {
                      this.fetchData(1, this.state.pagination.pageSize);
                    });
                  }}
                  style={{
                    position: "absolute",
                    right: "30px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    color: "#FF8F18",
                  }}
                  size={20}
                />
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <label
              htmlFor="intervalo"
              style={{
                color: "#FF8F18",
                fontWeight: "500",
                marginBottom: "0.5rem",
              }}
            >
              Quantidade de Spins
            </label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  type="number"
                  placeholder="De"
                  value={this.state.spinStart}
                  onChange={(e) => this.setState({ spinStart: e.target.value })}
                  style={{
                    padding: "0.5rem",
                    paddingRight: "30px",
                    borderRadius: "15px",
                    border: "1px solid #E3E3E3",
                    marginRight: "20px",
                    width: "150px",
                    height: "40px",
                    backgroundColor: "#FAFAFA",
                  }}
                />
                {this.state.spinStart && (
                  <X
                    onClick={() => {
                      this.setState(
                        { spinStart: "", users: [], page: 1 },
                        () => {
                          this.fetchData(1, this.state.pagination.pageSize);
                        }
                      );
                    }}
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#FF8F18",
                    }}
                    size={20}
                  />
                )}
              </div>
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  type="number"
                  placeholder="Até"
                  value={this.state.spinEnd}
                  onChange={(e) => this.setState({ spinEnd: e.target.value })}
                  style={{
                    padding: "0.5rem",
                    paddingRight: "30px",
                    borderRadius: "15px",
                    border: "1px solid #E3E3E3",
                    marginRight: "20px",
                    width: "150px",
                    height: "40px",
                    backgroundColor: "#FAFAFA",
                  }}
                />
                {this.state.spinEnd && (
                  <X
                    onClick={() => {
                      // Limpa o campo, reseta a listagem e volta para a página 1
                      this.setState({ spinEnd: "", users: [], page: 1 }, () => {
                        this.fetchData(1, this.state.pagination.pageSize);
                      });
                    }}
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#FF8F18",
                    }}
                    size={20}
                  />
                )}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>&nbsp;</div>
            <Button
              className="orange-bg"
              onClick={this.handleFilter}
              style={{
                // backgroundColor: "#E59A3D",
                color: "white",
                fontWeight: "bold",
                padding: "12px 24px",
                borderRadius: "10px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                border: "none",
                fontSize: "16px",
                cursor: "pointer",
                width: "200px",
                marginTop: 5,
              }}
              type="orange"
              title="Filtrar"
            />
          </div>
        </div>

        <div id="">
          <table
            style={{
              width: "100%",
              backgroundColor: "#fff",
              marginTop: "2rem",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid #eae4e4",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <tbody style={{ marginLeft: "10rem", marginRight: "10rem" }}>
              <tr style={{ textAlign: "left", fontSize: "14px" }}>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Nome</h3>
                </td>
                <td style={{ width: "18%" }}>
                  <h3 className="name">E-mail</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Telefone</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Spins</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">+Spins</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Ações</h3>
                </td>
              </tr>
            </tbody>
          </table>

          {this.renderList()}
        </div>
        <div id="plans-list">{this.renderPagination()}</div>
      </div>
    );
  }

  saveChanges = async (value, idUser) => {
    let upload = await Api.marketAdd(value, idUser);

    upload = upload.market;
    if (Utils.checkForErrors(this, upload)) {
      return;
    }

    upload = upload.data.add;
    if (Utils.checkForErrors(this, upload)) {
      return;
    }

    upload = upload.data;
    if (Utils.checkForErrors(this, upload)) {
      return;
    }

    toast.success("Crédito adicionado com sucesso!", {
      className: "toast-success",
    });

    this.fetchData(1, 200);
  };
}

export default ScreenUsersList;
