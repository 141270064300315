import React from "react";
import Api from "../apis/api";
import Utils from "../components/Utils";
import { X } from "lucide-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row } from "react-bootstrap";
import Button from "../components/common/Button";
import CoursePanelListItem from "../components/js/CoursePanelListItem";

class ScreenCoursesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      courses: [],
      published: "",
      category: "",
      findName: "",
      page: 1,
      order: "",
      error: 0,
    };
  }

  componentDidMount() {
    this.fetchData(
      this.state.published,
      this.state.category,
      this.state.findName,
      this.state.page,
      15,
      this.state.order
    );
  }

  handleClickNextPage = () => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.fetchData(
        this.state.published,
        this.state.category,
        this.state.findName,
        this.state.page,
        15,
        this.state.order
      )
    );
  };

  handleFilter = () => {
    // Reinicia a lista e a página para aplicar os filtros
    this.setState({ courses: [], page: 1 }, () => {
      this.fetchData(
        this.state.published,
        this.state.category,
        this.state.findName,
        1,
        15,
        this.state.order
      );
    });
  };

  async fetchData(published, category, query, page, pageSize, order) {
    this.setState({ loading: true });

    let courses = await Api.coursesPanelList(
      published,
      category,
      query,
      page,
      pageSize,
      order
    );

    courses = courses.courses;
    if (Utils.checkForErrors(this, courses)) {
      return;
    }

    courses = courses.data.panel_list;
    if (Utils.checkForErrors(this, courses)) {
      return;
    }

    courses = courses.data.list;
    //console.log('courses', courses);
    this.setState({ courses: [...this.state.courses, ...courses] }, () =>
      this.setState({ loading: false })
    );
  }

  renderList() {
    if (this.state.courses.length === 0) {
      return (
        <p style={{ fontFamily: "Averta", fontSize: "1.2rem" }}>
          Não há cursos cadastrados!
        </p>
      );
    }

    const coursesList = this.state.courses.map((course) => {
      return (
        <CoursePanelListItem
          onCourseDelete={this.handleCourseDelete}
          onCoursePublish={this.handleCoursePublish}
          key={course.id}
          item={course}
        />
      );
    });
    return coursesList;
  }

  handleCourseDelete = async (id) => {
    let del = await Api.coursesPanelDelete(id);

    del = del.courses;
    if (Utils.checkForErrors(this, del)) {
      return;
    }

    del = del.data.panel_rem;
    if (Utils.checkForErrors(this, del)) {
      return;
    }

    if (del.data) {
      // Resetando a lista e recarregando os dados
      this.setState({ courses: [], page: 1 }, () => {
        this.fetchData(
          this.state.published,
          this.state.category,
          this.state.findName,
          1,
          20,
          this.state.order
        );
      });
    }
  };

  handleCoursePublish = async (id, published) => {
    let pub = await Api.coursesPanelPublish(id, !published);

    pub = pub.courses;
    if (Utils.checkForErrors(this, pub)) {
      return;
    }

    pub = pub.data.panel_publish;
    if (Utils.checkForErrors(this, pub)) {
      return;
    }

    if (pub.data) {
      this.fetchData(this.state.published, "", "", 1, 20, "");
    }
  };

  render() {
    if (this.state.error !== 0) {
      return null;
    }

    return (
      <div
        className="main-content"
        style={{
          textAlign: "center",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <div
          className="content-header fluid flex-between"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "1rem",
            paddingTop: "1rem",
          }}
        >
          <span
            style={{ fontSize: "2rem", fontWeight: "bold", color: "#FF8F18" }}
          >
            Cursos
          </span>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: "#fff",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>&nbsp;</div>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.4rem",
                  marginRight: "1rem",
                  whiteSpace: "nowrap",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                Filtrar cursos
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label
                htmlFor="buscar-nome"
                style={{
                  color: "#FF8F18",
                  fontWeight: "500",
                  marginBottom: "0.5rem",
                }}
              >
                Nome
              </label>
              <div style={{ position: "relative", display: "inline-block" }}>
                <input
                  id="buscar-nome"
                  type="text"
                  placeholder="Buscar por nome"
                  value={this.state.findName}
                  onChange={(e) => this.setState({ findName: e.target.value })}
                  style={{
                    padding: "0.5rem",
                    paddingRight: "30px",
                    borderRadius: "15px",
                    border: "1px solid #E3E3E3",
                    marginRight: "20px",
                    width: "300px",
                    height: "40px",
                    backgroundColor: "#FAFAFA",
                  }}
                />
                {this.state.findName && (
                  <X
                    onClick={() => {
                      // Limpa o filtro, reseta a lista e volta para a página 1
                      this.setState(
                        { findName: "", courses: [], page: 1 },
                        () => {
                          this.fetchData(
                            this.state.published,
                            this.state.category,
                            "",
                            1,
                            15,
                            this.state.order
                          );
                        }
                      );
                    }}
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#FF8F18",
                    }}
                    size={20}
                  />
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label
                htmlFor="status"
                style={{
                  color: "#FF8F18",
                  fontWeight: "500",
                  marginBottom: "0.5rem",
                }}
              >
                Status
              </label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <select
                  id="status"
                  value={this.state.published}
                  onChange={(e) => {
                    const val = e.target.value;
                    this.setState({
                      published: val === "" ? "" : parseInt(val, 10),
                    });
                  }}
                  style={{
                    padding: "0.5rem",
                    borderRadius: "15px",
                    border: "1px solid #E3E3E3",
                    marginRight: "20px",
                    width: "150px",
                    height: "40px",
                    backgroundColor: "#FAFAFA",
                  }}
                >
                  <option value="">Todos</option>
                  {/* <option value="0">Ativos</option> */}
                  <option value="1">Publicados</option>
                  <option value="2">Não Publicados</option>
                  {/* <option value="3">Inativos</option> */}
                </select>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>&nbsp;</div>
              <Button
                onClick={this.handleFilter}
                className="orange-bg"
                style={{
                  // backgroundColor: "#E59A3D",
                  color: "white",
                  fontWeight: "bold",
                  padding: "12px 24px",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "none",
                  fontSize: "16px",
                  cursor: "pointer",
                  width: "200px",
                  marginTop: 5,
                }}
                type="orange"
                title="Filtrar"
              />
            </div>
          </div>

          <div className="content-header">
            <div>&nbsp;</div>
            <Link
              style={{ width: "240px" }}
              className="button orange-bg"
              to={"/cursos/editor"}
              title="Adicionar novo curso"
            >
              <FontAwesomeIcon icon="plus" />
              Adicionar novo curso
            </Link>
          </div>
        </div>

        <div style={{ paddingBottom: "2rem" }} id="courses-list">
          {this.renderList()}
        </div>

        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              marginBottom: "2rem",
            }}
            onClick={this.handleClickNextPage}
            type="orange"
            title={"Ver mais"}
          />
        </Row>
      </div>
    );
  }
}

export default ScreenCoursesList;
