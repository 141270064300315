import axios from "axios";
import { API_ROOT } from "../config";

class Api {
  constructor() {
    let baseURL = "";
    if (API_ROOT === "dev") {
      baseURL = "https://api.mrionline.com.br/";
    } else if (API_ROOT === "stag") {
      baseURL = "https://api.mrionline.com.br/";
    }

    console.log(process.env.REACT_APP_API_URL);

    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 60000,
      // headers: 'application/json'
    });

    this.api.interceptors.response.use(
      (response) => {
        if (response.data.status || response.status) {
          if (response.data.data !== undefined) {
            return Promise.resolve(response.data.data);
          } else {
            return Promise.resolve(response.data.total);
          }
        } else {
          console.log("response.data.status", response.data.status);

          return Promise.reject(response);
        }
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  // async getToken() {
  //     return await AsyncStorage.getItem('appToken');
  // }

  // async setTokenInHeader(token) {
  //     await AsyncStorage.setItem('appToken', token);
  //     this.api.defaults.headers.common['AuthToken'] = token;
  //     this.api.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
  // }

  // LOGIN
  login(user, pass) {
    const body = {
      login: {
        user,
        pass,
      },
    };

    return this.api.post("Auth/", body);
  }

  destroyAccount(email) {
    const body = {
      destroy_account: {
        email: email,
      },
    };

    return this.api.post("Auth/", body);
  }

  refresh(token) {
    const body = {
      refresh: {
        token,
      },
    };

    return this.api.post("Auth/", body);
  }

  async getToken() {
    return await localStorage.getItem("userToken");
  }

  async setTokenInHeader(token) {
    await localStorage.setItem("userToken", token);
    this.api.defaults.headers.common["AuthToken"] = token;
    this.api.defaults.headers.common["Content-Type"] =
      "application/json; charset=utf-8";
  }

  // COURSES

  coursesListUser(user_id) {
    const body = {
      courses: {
        list_courses_of_user: {
          user_id,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  coursesPanelList(published, category, query, page, pageSize, order) {
    const body = {
      courses: {
        panel_list: {
          published,
          category,
          query,
          page,
          pageSize,
          order,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  coursePanelView(id) {
    const body = {
      courses: {
        panel_view: {
          id,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  coursesPanelEditor(
    id,
    name,
    description,
    description_short,
    image,
    difficulty,
    duration,
    price,
    points,
    categories,
    learnings,
    teachers,
    sponsors,
    requirements,
    lessons,
    evaluations,
    stamps,
    certification_image_front,
    certification_image_back
  ) {
    const body = {
      courses: {
        panel_editor: {
          id,
          name,
          description,
          description_short,
          image,
          difficulty,
          duration,
          price,
          points,
          categories,
          learnings,
          teachers,
          sponsors,
          requirements,
          lessons,
          evaluations,
          stamps,
          certification_image_front,
          certification_image_back,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  coursesPanelDelete(id) {
    const body = {
      courses: {
        panel_rem: {
          id: id,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  coursesPanelPublish(id, published) {
    const body = {
      courses: {
        panel_publish: {
          id: id,
          status: published,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // EVENTS
  eventsPanelList(published, query, page, pageSize, order) {
    const body = {
      events: {
        panel_list: {
          published,
          query,
          page,
          pageSize,
          order,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  eventPanelView(id) {
    const body = {
      events: {
        panel_view: {
          id,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  eventsPanelEditor(
    id,
    name,
    description,
    description_short,
    image,
    date_period,
    local_name,
    local_street,
    local_number,
    local_complement,
    local_neighborhood,
    local_city,
    local_state,
    local_zipcode,
    local_country,
    images,
    link,
    lot_price_1,
    lot_date_1,
    lot_price_2,
    lot_date_2,
    lot_price_3,
    lot_date_3
  ) {
    const body = {
      events: {
        panel_editor: {
          id,
          name,
          description,
          description_short,
          image,
          date_period,
          local_name,
          local_street,
          local_number,
          local_complement,
          local_neighborhood,
          local_city,
          local_state,
          local_zipcode,
          local_country,
          images,
          link,
          lot_price_1,
          lot_date_1,
          lot_price_2,
          lot_date_2,
          lot_price_3,
          lot_date_3,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  eventsPanelDelete(id) {
    const body = {
      events: {
        panel_rem: {
          id: id,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  eventsPanelPublish(id, published) {
    const body = {
      events: {
        panel_publish: {
          id: id,
          status: published,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // TEACHERS
  teachersPanelList(page, pageSize) {
    const body = {
      teachers: {
        panel_list: {
          page,
          pageSize,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  teacherPanelView(id) {}

  teacherPanelEditor(id, name, lastName, formation, image) {
    const body = {
      teachers: {
        panel_editor: {
          id,
          name,
          lastname: lastName,
          formation,
          image,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  teacherPanelDelete(id) {
    const body = {
      teachers: {
        panel_rem: {
          id,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // STAMPS
  stampsPanelList(page, pageSize) {
    const body = {
      stamps: {
        panel_list: {
          page,
          pageSize,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  stampPanelView(id) {}

  stampPanelEditor(id, name, description, image) {
    const body = {
      stamps: {
        panel_editor: {
          id,
          name,
          description,
          image,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  stampPanelDelete(id) {
    const body = {
      stamps: {
        panel_rem: {
          id,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // SPONSORS
  sponsorsPanelList(page, pageSize) {
    const body = {
      sponsors: {
        panel_list: {
          page,
          pageSize,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  sponsorPanelView(id) {}

  sponsorPanelEditor(id, name, link, image) {
    const body = {
      sponsors: {
        panel_editor: {
          id,
          name,
          link,
          image,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  sponsorPanelDelete(id) {
    const body = {
      sponsors: {
        panel_rem: {
          id,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // CATEGORIES
  categoriesPanelList(page, pageSize) {
    const body = {
      courses_categories: {
        panel_list: {
          page,
          pageSize,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  categoriesPanelEditor(name) {
    const body = {
      courses_categories: {
        panel_editor: {
          name,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // STATS
  statsPanelList(date_start, date_end) {
    const body = {
      stats: {
        panel_list: {
          date_start,
          date_end,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // MARKET
  marketPanelList(name, date_start, date_end) {
    const body = {
      market: {
        panel_list: {
          name,
          date_start,
          date_end,
          pageSize: 200,
          is_extract: true,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  marketAdd(value, id_user) {
    const body = {
      market: {
        add: {
          value,
          id_user,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // PLANS
  plansPanelList(page, pageSize) {
    const body = {
      plans: {
        list: {
          page,
          pageSize,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  planPanelView(id) {}

  planPanelEditor(id, price, spins_count, discount, sale_price) {
    const body = {
      plans: {
        panel_editor: {
          id,
          price,
          spins_count,
          discount,
          sale_price,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  planPanelDelete(id) {
    const body = {
      plans: {
        panel_rem: {
          id,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // USERS

  updateUser(name, lastname, email, phoneNumber) {
    const body = {
      updateUser: {
        name,
        lastname,
        email,
        phone_number: phoneNumber,
      },
    };

    return this.api.post("auth/", body);
  }

  userList(page, pageSize, findName = "", spinStart = "", spinEnd = "") {
    const filter = {};
    if (findName) {
      filter.find_name = findName;
    }
    if (spinStart !== "") {
      filter.spin_start = parseInt(spinStart, 10);
    }
    if (spinEnd !== "") {
      filter.spin_end = parseInt(spinEnd, 10);
    }

    const body = {
      users: {
        list: {
          page,
          pageSize,
          order: "name asc",
          filter: Object.keys(filter).length > 0 ? filter : undefined,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  // EVENTS ATTENDANCES
  eventsAttendancesPanelList(id_event, date_start, date_end) {
    const body = {
      events_attendances: {
        panel_list: {
          id_event,
          date_start,
          date_end,
          page: 1,
          pageSize: 300,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  eventAttendancesPanelView(id) {}

  eventAttendancesPanelEditor(id_event, id_user, create_time) {
    const body = {
      events_attendances: {
        panel_editor: {
          id_event,
          id_user,
          create_time,
        },
      },
    };

    return this.api.post("Data/", body);
  }

  eventAttendancesPanelDelete(id) {
    const body = {
      events_attendances: {
        panel_rem: {
          id,
        },
      },
    };

    return this.api.post("Data/", body);
  }
}

export default new Api();
