import React from "react";
import Api from "../apis/api";
import Utils from "../components/Utils";
import Button from "../components/common/Button";
import {
  ChevronFirst,
  ChevronLast,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { X } from "lucide-react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { pt } from "date-fns/locale";
registerLocale("pt", pt);
setDefaultLocale("pt");

let moment = require("moment");
let trLocale = require("moment/locale/pt-br");
moment.defineLocale("pt-br", trLocale);

class ScreenFinances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: 0,
      market: [],
      name: "",
      dateStart: null,
      dateEnd: null,
      pagination: {
        page: 1,
        pages: 1,
        pageSize: 5,
        total: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData("", "", "");
  }

  dateParse(create_time) {
    let createTime = new Date(1970, 0, 1, 0, 0, 0, 0);
    createTime.setSeconds(create_time);
    createTime.setHours(createTime.getHours() - 3);
    return createTime;
  }

  async fetchData(name, dateStart, dateEnd) {
    this.setState({ loading: true, market: [] });
    let market = await Api.marketPanelList(name, dateStart, dateEnd);
    market = market.market;
    if (Utils.checkForErrors(this, market)) {
      return;
    }

    market = market.data.panel_list;
    if (Utils.checkForErrors(this, market)) {
      return;
    }

    market = market.data;
    for (let i = 0; i < market.length; i++) {
      market[i]["create_date"] = moment(
        this.dateParse(market[i]["create_time"])
      ).format("DD/MM/YYYY");
      market[i]["type_payment"] =
        market[i]["id_course"] !== 0
          ? "Curso"
          : market[i]["id_event"] !== 0
          ? "Evento"
          : "Crédito";
    }

    // Configura os dados de paginação com base no total de itens
    const total = market.length;
    const { pageSize } = this.state.pagination;
    const pages = Math.ceil(total / pageSize);

    this.setState({
      market,
      loading: false,
      pagination: {
        ...this.state.pagination,
        total,
        pages,
        page: 1,
      },
    });
  }

  handlePreviousPage = () => {
    const { page } = this.state.pagination;
    if (page > 1) {
      console.log(`⬅️ Indo para a página ${page - 1}`);
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: page - 1 },
      }));
    }
  };

  handleNextPage = () => {
    const { page, pages } = this.state.pagination;
    if (page < pages) {
      console.log(`➡️ Indo para a página ${page + 1}`);
      this.setState((prevState) => ({
        pagination: { ...prevState.pagination, page: page + 1 },
      }));
    }
  };

  handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    const total = this.state.pagination.total;
    const newPages = Math.ceil(total / newPageSize);
    this.setState((prevState) => ({
      pagination: {
        ...prevState.pagination,
        pageSize: newPageSize,
        pages: newPages,
        page: 1,
      },
    }));
  };

  renderPagination() {
    const { page, pages, pageSize, total } = this.state.pagination;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        {/* Seletor de linhas por página */}
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "30px" }}
        >
          <span>Linhas por página: </span>
          <select
            value={pageSize}
            onChange={this.handlePageSizeChange}
            style={{ margin: "0 10px" }}
          >
            {[10, 15, 20, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div style={{ marginRight: "30px" }}>
          <span>
            {(page - 1) * pageSize + 1} - {Math.min(page * pageSize, total)} de{" "}
            {total}
          </span>
        </div>

        <button
          onClick={() =>
            this.setState({ pagination: { ...this.state.pagination, page: 1 } })
          }
          disabled={page === 1}
          style={{
            marginLeft: "10px",
            background: "none",
            border: "none",
            cursor: page === 1 ? "default" : "pointer",
          }}
        >
          <ChevronFirst
            style={{ fontSize: "20px", color: page === 1 ? "#ACACAC" : "#000" }}
          />
        </button>

        <button
          onClick={this.handlePreviousPage}
          disabled={page === 1}
          style={{
            marginLeft: "5px",
            background: "none",
            border: "none",
            cursor: page === 1 ? "default" : "pointer",
          }}
        >
          <ChevronLeft
            style={{ fontSize: "20px", color: page === 1 ? "#ACACAC" : "#000" }}
          />
        </button>

        <button
          onClick={this.handleNextPage}
          disabled={page === pages}
          style={{
            marginLeft: "5px",
            background: "none",
            border: "none",
            cursor: page === pages ? "default" : "pointer",
          }}
        >
          <ChevronRight
            style={{
              fontSize: "20px",
              color: page === pages ? "#ACACAC" : "#000",
            }}
          />
        </button>

        <button
          onClick={() =>
            this.setState({
              pagination: { ...this.state.pagination, page: pages },
            })
          }
          disabled={page === pages}
          style={{
            marginLeft: "5px",
            background: "none",
            border: "none",
            cursor: page === pages ? "default" : "pointer",
          }}
        >
          <ChevronLast
            style={{
              fontSize: "20px",
              color: page === pages ? "#ACACAC" : "#000",
            }}
          />
        </button>
      </div>
    );
  }

  renderMarketList() {
    const { market, pagination } = this.state;
    if (market.length === 0) {
      return (
        <p style={{ fontFamily: "Averta", fontSize: "1.2rem" }}>
          Informações não localizadas!
        </p>
      );
    }

    // Exibe somente os itens da página atual
    const startIndex = (pagination.page - 1) * pagination.pageSize;
    const currentMarkets = market.slice(
      startIndex,
      startIndex + pagination.pageSize
    );

    return (
      <>
        <div
          style={{
            width: "100%",
            backgroundColor: "#fff",
            marginTop: "2rem",
            border: "1px solid #eae4e4",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              backgroundColor: "#fff",
              border: "1px solid #eae4e4",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              overflow: "hidden",
            }}
          >
            <thead
              style={{
                backgroundColor: "#fff",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              <tr style={{ fontSize: "17px", backgroundColor: "#fff" }}>
                <th
                  style={{
                    padding: "12px 16px",
                    width: "20%",
                    paddingTop: "25px",
                    paddingBottom: "25px",
                  }}
                >
                  Usuário
                </th>
                <th style={{ padding: "12px 16px", width: "20%" }}>E-mail</th>
                <th style={{ padding: "12px 16px", width: "20%" }}>
                  Atividade
                </th>
                <th style={{ padding: "12px 16px", width: "10%" }}>Tipo</th>
                <th style={{ padding: "12px 16px", width: "10%" }}>Data</th>
                <th style={{ padding: "12px 16px", width: "10%" }}>Spins</th>
                <th style={{ padding: "12px 16px", width: "10%" }}>Valor</th>
              </tr>
            </thead>
            <tbody>
              {currentMarkets.map((item, index) => {
                let description = item.description.replace(/&#39;/gim, "'");
                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#F9F9F9",
                      textAlign: "left",
                      border: "1px solid #eae4e4",
                      height: "71px",
                    }}
                  >
                    <td style={{ padding: "12px 16px" }}>{item.user_name}</td>
                    <td style={{ padding: "12px 16px" }}>{item.email}</td>
                    <td style={{ padding: "12px 16px" }}>{description}</td>
                    <td style={{ padding: "12px 16px" }}>
                      {item.type_payment}
                    </td>
                    <td style={{ padding: "12px 16px" }}>{item.create_date}</td>
                    <td style={{ padding: "12px 16px" }}>{item.spin_qty}</td>
                    <td style={{ padding: "12px 16px" }}>
                      R$ {item.value.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div id="plans-list">{this.renderPagination()}</div>
      </>
    );
  }

  handleChangeDate = (type, date) => {
    if (type === "start") {
      this.setState({ dateStart: date });
    } else {
      this.setState({ dateEnd: date });
    }
  };

  render() {
    return (
      <div
        className="main-content"
        style={{ textAlign: "center", marginLeft: "1rem", marginRight: "1rem" }}
      >
        <div className="">
          <div
            className="content-header fluid centered flex-between min-600"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: "2rem",
              paddingTop: "1rem",
            }}
          >
            <span
              style={{ fontSize: "2rem", fontWeight: "bold", color: "#FF8F18" }}
            >
              Financeiro
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: "#fff",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              borderRadius: "10px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>&nbsp;</div>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "1.4rem",
                  marginRight: "1rem",
                  whiteSpace: "nowrap",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                Filtrar dados
              </span>
            </div>

            {/* Campo Nome com ícone X para limpar */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label
                htmlFor="buscar-nome"
                style={{
                  color: "#FF8F18",
                  fontWeight: "500",
                  marginBottom: "0.5rem",
                }}
              >
                Nome
              </label>
              <div style={{ position: "relative", display: "inline-block" }}>
                <div className="input-group-section">
                  <input
                    type="text"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                    placeholder="Buscar por nome"
                    style={{
                      padding: "0.5rem",
                      paddingRight: "30px",
                      borderRadius: "15px",
                      border: "1px solid #E3E3E3",
                      marginRight: "20px",
                      width: "300px",
                      height: "40px",
                      backgroundColor: "#FAFAFA",
                    }}
                  />
                </div>
                {this.state.name && (
                  <X
                    onClick={() => {
                      this.setState(
                        {
                          name: "",
                          market: [],
                          pagination: { ...this.state.pagination, page: 1 },
                        },
                        () => {
                          // Recarrega a listagem sem filtro de nome
                          this.fetchData("", 0, 0);
                        }
                      );
                    }}
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#FF8F18",
                    }}
                    size={20}
                  />
                )}
              </div>
            </div>

            {/* Campos de Data com ícones X para limpar */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <label
                htmlFor="intervalo"
                style={{
                  color: "#FF8F18",
                  fontWeight: "500",
                  marginBottom: "0.5rem",
                }}
              >
                Data
              </label>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* Data Inicial */}
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                >
                  <div className="input-group-section">
                    <DatePicker
                      selected={this.state.dateStart}
                      onChange={(date) => this.handleChangeDate("start", date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="De"
                      customInput={
                        <input
                          style={{
                            padding: "0.5rem",
                            borderRadius: "15px",
                            border: "1px solid #E3E3E3",
                            width: "150px",
                            height: "40px",
                            backgroundColor: "#FAFAFA",
                            fontSize: "14px",
                            textAlign: "left",
                            outline: "none",
                            transition: "border 0.3s ease-in-out",
                          }}
                        />
                      }
                    />
                  </div>
                  {this.state.dateStart && (
                    <X
                      onClick={() => {
                        this.setState(
                          {
                            dateStart: null,
                            market: [],
                            pagination: { ...this.state.pagination, page: 1 },
                          },
                          () => {
                            let dateEnd = 0;
                            if (this.state.dateEnd !== null) {
                              dateEnd =
                                new Date(
                                  this.state.dateEnd.getUTCFullYear() +
                                    "-" +
                                    (this.state.dateEnd.getUTCMonth() + 1) +
                                    "-" +
                                    this.state.dateEnd.getUTCDate() +
                                    " 23:59:59"
                                ).getTime() / 1000;
                            }
                            this.fetchData(this.state.name, 0, dateEnd);
                          }
                        );
                      }}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "#FF8F18",
                      }}
                      size={20}
                    />
                  )}
                </div>

                {/* Data Final */}
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                >
                  <div className="input-group-section">
                    <DatePicker
                      selected={this.state.dateEnd}
                      onChange={(date) => this.handleChangeDate("end", date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Até"
                      customInput={
                        <input
                          style={{
                            padding: "0.5rem",
                            borderRadius: "15px",
                            border: "1px solid #E3E3E3",
                            width: "150px",
                            height: "40px",
                            backgroundColor: "#FAFAFA",
                            fontSize: "14px",
                            textAlign: "left",
                            outline: "none",
                            transition: "border 0.3s ease-in-out",
                          }}
                        />
                      }
                    />
                  </div>
                  {this.state.dateEnd && (
                    <X
                      onClick={() => {
                        this.setState(
                          {
                            dateEnd: null,
                            market: [],
                            pagination: { ...this.state.pagination, page: 1 },
                          },
                          () => {
                            let dateStart = 0;
                            if (this.state.dateStart !== null) {
                              dateStart =
                                new Date(
                                  this.state.dateStart.getUTCFullYear() +
                                    "-" +
                                    (this.state.dateStart.getUTCMonth() + 1) +
                                    "-" +
                                    this.state.dateStart.getUTCDate() +
                                    " 00:00:00"
                                ).getTime() / 1000;
                            }
                            this.fetchData(this.state.name, dateStart, 0);
                          }
                        );
                      }}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "#FF8F18",
                      }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>&nbsp;</div>
              <Button
                onClick={() => {
                  let dateStart = 0,
                    dateEnd = 0;
                  if (this.state.dateStart !== null) {
                    dateStart =
                      new Date(
                        this.state.dateStart.getUTCFullYear() +
                          "-" +
                          (this.state.dateStart.getUTCMonth() + 1) +
                          "-" +
                          this.state.dateStart.getUTCDate() +
                          " 00:00:00"
                      ).getTime() / 1000;
                  }
                  if (this.state.dateEnd !== null) {
                    dateEnd =
                      new Date(
                        this.state.dateEnd.getUTCFullYear() +
                          "-" +
                          (this.state.dateEnd.getUTCMonth() + 1) +
                          "-" +
                          this.state.dateEnd.getUTCDate() +
                          " 23:59:59"
                      ).getTime() / 1000;
                  }
                  this.fetchData(this.state.name, dateStart, dateEnd);
                }}
                style={{
                  backgroundColor: "#E59A3D",
                  color: "white",
                  fontWeight: "bold",
                  padding: "12px 24px",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  border: "none",
                  fontSize: "16px",
                  cursor: "pointer",
                  width: "200px",
                  marginTop: "0.5rem",
                }}
                type="orange"
                title="Filtrar"
              />
            </div>
          </div>
        </div>
        <div id="transactions-log">{this.renderMarketList()}</div>
      </div>
    );
  }
}

export default ScreenFinances;
