import React from "react";
import Api from "../apis/api";

import { API_ROOT } from "../config";
import { Redirect } from "react-router-dom";
import queryString from "query-string";

import { uploadFile } from "react-s3";
import Utils from "../components/Utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import TextareaAutosize from "react-autosize-textarea";

import Button from "../components/common/Button";
import LessonItem from "../components/js/LessonItem";
import EvaluationItem from "../components/js/EvaluationItem";
import DropdownSelection from "../components/common/DropdownSelection";

class ScreenCourseEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: 0,

      id: "",

      name: "",
      description: "",
      description_short: "",
      image: "",

      difficulty: "",
      duration: "",
      price: "",
      points: "",

      categories: [],
      learnings: [],
      teachers: [],
      sponsors: [],
      stamps: [],
      requirements: [],

      lessons: [],
      lessonsChanged: [],
      evaluations: [],
      evaluationsChanged: [],

      certification_image_front: "",
      certification_image_back: "",

      inputLearnings: "",

      redirect: false,

      allCategories: [],
      allTeachers: [],
      allSponsors: [],
      allStamps: [],
      allCourses: [],
    };

    this.handleEvaluationEdit = this.handleEvaluationEdit.bind(this);
    this.handleEvaluationDelete = this.handleEvaluationDelete.bind(this);
  }

  componentDidMount() {
    this.setState({
      redirect: false,
    });

    const parsed = queryString.parse(this.props.location.search);
    console.log("ID => ", parsed.id);
    if ("id" in parsed) {
      this.fetchData(parsed.id);
    }

    this.fetchListElements();
  }

  // componentDidUpdate = (previousProps) => {

  //     let location = previousProps.history.location;
  //     let nowPathname = '';
  //     let prevPathname = '';

  //     if ('pathname' in location) {
  //         nowPathname = location.pathname;
  //     }

  //     if ('state' in location && location.state !== undefined) {
  //         prevPathname = location.state.from.pathname;
  //     }

  //     if (prevPathname !== '' && nowPathname !== '/cursos/editor' && prevPathname !== nowPathname) {
  //         console.log(prevPathname);
  //         console.log(nowPathname);

  //         this.setState({
  //             redirect: false
  //         });

  //         const parsed = queryString.parse(this.props.location.search);

  //         if ('id' in parsed) {
  //             this.fetchData(parsed.id);
  //         }

  //         this.fetchListElements();
  //     }
  // }

  render() {
    let selectedTeachers = this.state.teachers.map((item) => item.id);
    let selectedStamps = this.state.stamps.map((item) => item.id);
    let selectedSponsors = this.state.sponsors.map((item) => item.id);
    let selectedCourses = this.state.requirements.map((item) => item.id);
    let selectedCategories = this.state.categories.map((item) => item.id);

    if (this.state.error !== 0) {
      return null;
    }

    return (
      <div className="main-content min-600 centered form">
        {/* Name */}
        <div className="input-group-section">
          <label className="label-section">Nome (*)</label>
          <input
            type="text"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
            placeholder="digite aqui o nome do curso..."
          />
        </div>

        {/* Description */}
        <div
          className="input-group-section"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* Short */}
          <div className="common-group-item" style={{ width: "37%" }}>
            <label className="label-section">Descrição curta (*)</label>
            <TextareaAutosize
              value={this.state.description_short}
              onChange={(e) =>
                this.setState({ description_short: e.target.value })
              }
              placeholder="digite aqui uma descrição curta p/ o curso..."
            />
          </div>
          {/* Long */}
          <div className="common-group-item" style={{ width: "57%" }}>
            <label className="label-section">Descrição longa</label>
            <TextareaAutosize
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
              placeholder="digite aqui uma descrição detalhada p/ o curso..."
            />
          </div>
        </div>

        {/* Number values */}
        <div
          className="input-group-section"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {/* Duration */}
          <div className="common-group-item" style={{ width: "20%" }}>
            <label className="label-section">Duração (em horas) (*)</label>
            <input
              type="number"
              value={this.state.duration}
              min="1"
              max="9999"
              onChange={(e) => this.setState({ duration: e.target.value })}
            />
          </div>
          {/* Difficulty */}
          <div className="common-group-item" style={{ width: "20%" }}>
            <label className="label-section">Dificuldade (de 1 a 5) (*)</label>
            <input
              type="number"
              value={this.state.difficulty}
              min="1"
              max="5"
              onChange={(e) => this.setState({ difficulty: e.target.value })}
            />
          </div>
          {/* Price - spins */}
          <div className="common-group-item" style={{ width: "20%" }}>
            <label className="label-section">
              Preço em spins (ex.: 250) (*)
            </label>
            <input
              type="number"
              value={this.state.price}
              min="1"
              max="999999"
              onChange={(e) => this.setState({ price: e.target.value })}
            />
          </div>
          {/* Points - teslas */}
          <div className="common-group-item" style={{ width: "20%" }}>
            <label className="label-section">Teslas (ex.: 0,5) (*)</label>
            <input
              type="number"
              value={this.state.points}
              min="1"
              max="999999"
              onChange={(e) => this.setState({ points: e.target.value })}
            />
          </div>
        </div>

        {/* Cover picture */}
        <div className="input-group-section input-photo-section">
          <label className="label-section">
            Foto de capa (proporção 4:3, larg. 600px x alt. 200px, jpg ou png)
          </label>
          <div
            className="photo-preview"
            style={{ backgroundImage: `url(${this.state.image})` }}
          />
          <input
            type="file"
            name="edit-photo"
            id="edit-photo"
            onChange={this.handleSelectedFile}
          />
          <label className="label-input" htmlFor="edit-photo">
            Buscar
          </label>
        </div>

        {/* Learnings */}
        <div className="input-group-section input-list-section">
          <label className="label-section">O que o usuário vai aprender</label>
          <TextareaAutosize
            value={this.state.inputLearnings}
            onChange={(e) => this.setState({ inputLearnings: e.target.value })}
            onKeyPress={this.handleLearningAdd}
            placeholder="digite aqui o que o usuário vai aprender e pressione enter..."
            async
          />
          <ul className="input-items-section">
            {this.renderLearningsItemsView()}
          </ul>
        </div>

        {/* Categories */}
        <DropdownSelection
          label="Categorias"
          type={3}
          placeholder="Procure aqui por uma categoria..."
          items={this.state.allCategories}
          selectedItems={selectedCategories}
          addSelectedItem={(item) => {
            if (selectedCategories.includes(item.id)) {
              return;
            }

            this.setState({ categories: [...this.state.categories, item] });
          }}
          removeSelectedItem={(index) => {
            this.setState({
              categories: [
                ...this.state.categories.slice(0, index),
                ...this.state.categories.slice(index + 1),
              ],
            });
          }}
          onCreateCategory={this.handleCreateCategory}
        />

        {/* Teachers */}
        <DropdownSelection
          label="Professores"
          type={1}
          placeholder="Procure aqui por um professor..."
          items={this.state.allTeachers}
          selectedItems={selectedTeachers}
          addSelectedItem={(item) => {
            if (selectedTeachers.includes(item.id)) {
              return;
            }

            this.setState({ teachers: [...this.state.teachers, item] });
          }}
          removeSelectedItem={(index) => {
            this.setState({
              teachers: [
                ...this.state.teachers.slice(0, index),
                ...this.state.teachers.slice(index + 1),
              ],
            });
          }}
        />

        {/* Stamps */}
        <DropdownSelection
          label="Selos"
          type={2}
          placeholder="Procure aqui por um selo..."
          items={this.state.allStamps}
          selectedItems={selectedStamps}
          addSelectedItem={(item) => {
            if (selectedStamps.includes(item.id)) {
              return;
            }

            this.setState({ stamps: [...this.state.stamps, item] });
          }}
          removeSelectedItem={(index) => {
            this.setState({
              stamps: [
                ...this.state.stamps.slice(0, index),
                ...this.state.stamps.slice(index + 1),
              ],
            });
          }}
        />

        {/* Sponsors */}
        <DropdownSelection
          label="Patrocinadores"
          type={2}
          placeholder="Procure aqui por um patrocinador..."
          items={this.state.allSponsors}
          selectedItems={selectedSponsors}
          addSelectedItem={(item) => {
            if (selectedSponsors.includes(item.id)) {
              return;
            }

            this.setState({ sponsors: [...this.state.sponsors, item] });
          }}
          removeSelectedItem={(index) => {
            this.setState({
              sponsors: [
                ...this.state.sponsors.slice(0, index),
                ...this.state.sponsors.slice(index + 1),
              ],
            });
          }}
        />

        {/* Requirements */}
        <DropdownSelection
          label="Pré-requisitos"
          type={2}
          placeholder="Procure aqui por um curso..."
          items={this.state.allCourses}
          selectedItems={selectedCourses}
          addSelectedItem={(item) => {
            if (selectedCourses.includes(item.id)) {
              return;
            }

            this.setState({ requirements: [...this.state.requirements, item] });
          }}
          removeSelectedItem={(index) => {
            this.setState({
              requirements: [
                ...this.state.requirements.slice(0, index),
                ...this.state.requirements.slice(index + 1),
              ],
            });
          }}
        />

        {/* Lessons */}
        <div className="input-group-section input-list-section">
          <label className="label-section">Lista de aulas (*)</label>
          <ul id="lessons-list" className="input-items-section">
            {this.renderLessonsItemsView()}
          </ul>
          <Button
            onClick={this.handleLessonAdd}
            style={{
              marginRight: "0",
              marginLeft: "auto",
              fontSize: "0.8rem",
              padding: "0.5rem",
            }}
            type="orange"
            title="Adicionar nova aula"
          />
        </div>

        {/* Evaluations */}
        <div
          className="input-group-section"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <label className="label-section">Avaliações</label>
          {this.renderEvaluationsItemsView()}
        </div>

        {/* Certificados */}
        <div className="input-group-section" style={{ display: "flex" }}>
          <label className="label-section">
            Certificados (largura 970px x altura 666px, tipo jpg ou png)
          </label>
          <div
            style={{
              textAlign: "left",
              paddingTop: "1rem",
              marginTop: "0.75rem",
            }}
          >
            <div className="certificate-container">
              <label className="certificate-label">FRENTE</label>
              <div
                className="photo-preview"
                style={{
                  backgroundImage: `url(${this.state.certification_image_front})`,
                }}
              />
              <input
                type="file"
                name="edit-cert-front"
                id="edit-cert-front"
                onChange={this.handleCertificateFrontFile}
              />
              <label className="label-input" htmlFor="edit-cert-front">
                Buscar
              </label>
            </div>
            <div className="certificate-container">
              <label className="certificate-label">VERSO</label>
              <div
                className="photo-preview"
                style={{
                  backgroundImage: `url(${this.state.certification_image_back})`,
                }}
              />
              <input
                type="file"
                name="edit-cert-back"
                id="edit-cert-back"
                onChange={this.handleCertificateBackFile}
              />
              <label className="label-input" htmlFor="edit-cert-back">
                Buscar
              </label>
            </div>
          </div>
        </div>

        {/* Save */}
        <div style={{ paddingBottom: 40 }} className="button-container">
          <Button
            onClick={this.handleCourseSave}
            style={{ marginLeft: "auto", marginRight: "auto" }}
            type="orange"
            title={this.state.id ? "Salvar alterações" : "Criar curso"}
          />
          {this.renderRedirect()}
        </div>
      </div>
    );
  }

  async fetchData(id) {
    id = parseInt(id);
    this.setState({ loading: true });

    let course = await Api.coursePanelView(id);

    course = course.courses;
    if (Utils.checkForErrors(this, course)) {
      return;
    }

    course = course.data.panel_view;
    if (Utils.checkForErrors(this, course)) {
      return;
    }

    course = course.data;
    this.setState(
      {
        id: course.id,
        name: course.name,
        description: course.description,
        description_short: course.description_short,
        image: course.image,

        difficulty: course.difficulty,
        duration: course.duration,
        price: course.price,
        points: course.points,

        categories: course.categories,
        learnings: course.learnings,
        teachers: course.teachers,
        sponsors: course.sponsors,
        stamps: course.stamps,
        requirements: course.requirements,

        lessons: course.lessons,
        lessonsChanged: new Array(course.lessons.length).fill(false),
        evaluations: course.evaluations,
        evaluationsChanged: new Array(2).fill(false),

        certification_image_front: course.certification_image_front,
        certification_image_back: course.certification_image_back,
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  // checkForErrors(element) {
  //     if (element.error_code !== 0) {
  //         this.setState({ error: element.error_code });

  //         toast.error(`Erro executando esta operação! Código de erro: ${element.error_code}`, {
  //             className: 'toast-error'
  //         });

  //         return true;
  //     }

  //     return false;
  // };

  fetchListElements() {
    let allCategories = Api.categoriesPanelList(1, 100);
    allCategories.then((data) => {
      if (data.courses_categories.error_code === 0) {
        this.setState({
          allCategories: data.courses_categories.data.panel_list.data,
        });
        //console.log(data.courses_categories.data.panel_list.data);
      }
    });

    let allTeachers = Api.teachersPanelList(1, 100);
    allTeachers.then((data) => {
      if (data.teachers.error_code === 0) {
        if (data.teachers.data.panel_list.error_code === 0) {
          this.setState({
            allTeachers: data.teachers.data.panel_list.data.list,
          });
          //console.log(data.teachers.data.panel_list.data.list);
        }
      }
    });

    let allSponsors = Api.sponsorsPanelList(1, 100);
    allSponsors.then((data) => {
      if (data.sponsors.error_code === 0) {
        if (data.sponsors.data.panel_list.error_code === 0) {
          this.setState({
            allSponsors: data.sponsors.data.panel_list.data.list,
          });
          //console.log(data.sponsors.data.panel_list.data.list);
        }
      }
    });

    let allStamps = Api.stampsPanelList(1, 100);
    allStamps.then((data) => {
      if (data.stamps.error_code === 0) {
        if (data.stamps.data.panel_list.error_code === 0) {
          this.setState({ allStamps: data.stamps.data.panel_list.data.list });
          //console.log(data.stamps.data.panel_list.data.list);
        }
      }
    });

    let allCourses = Api.coursesPanelList("", "", "", 1, 200, "");
    allCourses.then((data) => {
      if (data.courses.error_code === 0) {
        if (data.courses.data.panel_list.error_code === 0) {
          const parsed = queryString.parse(this.props.location.search);
          this.setState({
            allCourses: data.courses.data.panel_list.data.list.filter(
              (curse) => curse.id !== Number(parsed.id)
            ),
          });
        }
      }
    });
  }

  handleCreateCategory = (data) => {
    if (data.id && data.name) {
      let category = {
        id: data.id,
        name: data.name,
      };

      this.setState({
        categories: [...this.state.categories, category],
        allCategories: [...this.state.allCategories, category],
      });
    }
  };

  handleSelectedFile = (event) => {
    let file = event.target.files[0];
    let config = {
      bucketName: "mri-ead",
      bucketRoot: "https://s3-sa-east-1.amazonaws.com/mri-ead/",
      region: "sa-east-1",
      accessKeyId: "AKIAIBBX5O7CKQWW22HA",
      secretAccessKey: "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",
      dirName: "images",
    };

    // TODO: LOADER SPINNER
    uploadFile(file, config, API_ROOT).then(
      (data) => {
        this.setState({ image: data.location });
        toast.success("Imagem enviada com sucesso!", {
          className: "toast-success",
        });
      },
      function () {
        toast.error(`Erro subindo a imagem! Tente novamente!`, {
          className: "toast-error",
        });
      }
    );
  };

  handleCertificateFrontFile = (event) => {
    let file = event.target.files[0];
    let config = {
      bucketName: "mri-ead",
      bucketRoot: "https://s3-sa-east-1.amazonaws.com/mri-ead/",
      region: "sa-east-1",
      accessKeyId: "AKIAIBBX5O7CKQWW22HA",
      secretAccessKey: "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",
      dirName: "images",
    };

    // TODO: LOADER SPINNER
    uploadFile(file, config, API_ROOT).then(
      (data) => {
        this.setState({ certification_image_front: data.location });
        toast.success("Imagem enviada com sucesso!", {
          className: "toast-success",
        });
      },
      function () {
        toast.error(`Erro subindo a imagem! Tente novamente!`, {
          className: "toast-error",
        });
      }
    );
  };

  handleCertificateBackFile = (event) => {
    let file = event.target.files[0];
    let config = {
      bucketName: "mri-ead",
      bucketRoot: "https://s3-sa-east-1.amazonaws.com/mri-ead/",
      region: "sa-east-1",
      accessKeyId: "AKIAIBBX5O7CKQWW22HA",
      secretAccessKey: "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",
      dirName: "images",
    };

    // TODO: LOADER SPINNER
    uploadFile(file, config, API_ROOT).then(
      (data) => {
        this.setState({ certification_image_back: data.location });
        toast.success("Imagem enviada com sucesso!", {
          className: "toast-success",
        });
      },
      function () {
        toast.error(`Erro subindo a imagem! Tente novamente!`, {
          className: "toast-error",
        });
      }
    );
  };

  handleLearningAdd = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    event.preventDefault();

    if (this.state.inputLearnings !== "") {
      this.setState(
        {
          learnings: [
            ...this.state.learnings,
            Utils.capitalizeFirstLetter(this.state.inputLearnings),
          ],
        },
        () => this.setState({ inputLearnings: "" })
      );
    }
  };

  handleLearningRemove(index) {
    this.setState({
      learnings: [
        ...this.state.learnings.slice(0, index),
        ...this.state.learnings.slice(index + 1),
      ],
    });
  }

  handleLessonDelete = (index) => {
    return this.setState({
      lessonsChanged: [
        ...this.state.lessonsChanged.slice(0, index),
        ...this.state.lessonsChanged.slice(index + 1),
      ],
      lessons: [
        ...this.state.lessons.slice(0, index),
        ...this.state.lessons.slice(index + 1),
      ],
    });
  };

  handleLessonAdd = () => {
    let lesson = {
      id: 0,
      name: "Aula sem título",
      elements: [],
    };

    this.setState({
      lessons: [...this.state.lessons, lesson],
      lessonsChanged: [...this.state.lessonsChanged, true],
    });
  };

  handleLessonEdit = (indexToChange, name, elements) => {
    let lessons = this.state.lessons.map((lesson, index) => {
      if (index === indexToChange) {
        return {
          id: lesson.id,
          name: name,
          elements: elements,
        };
      } else {
        return lesson;
      }
    });
    let lessonsChanged = this.state.lessonsChanged;
    lessonsChanged[indexToChange] = true;

    toast.success("Aula editada com sucesso!", {
      className: "toast-success",
    });

    this.setState(
      {
        lessons: lessons,
        lessonsChanged: lessonsChanged,
      },
      () => this.handleCourseSave()
    );
  };

  handleEvaluationDelete = (index) => {
    if (this.state.evaluations.length === 1) {
      return this.setState({
        evaluations: [],
      });
    }

    // let evaluationsChanged = this.state.evaluationsChanged.map(evaluationChanged => {

    // });

    return this.setState({
      //evaluationsChanged: [...this.state.evaluationsChanged.slice(0, index), ...this.state.evaluationsChanged.slice(index + 1)],
      evaluations: [
        ...this.state.evaluations.slice(0, index),
        ...this.state.evaluations.slice(index + 1),
      ],
    });
  };

  handleEvaluationEdit = (indexToChange, name, description, elements) => {
    let evaluations = this.state.evaluations.map((evaluation, index) => {
      if (index === indexToChange) {
        return {
          id: evaluation.id,
          type: evaluation.type,
          name: name,
          description: description,
          elements: elements,
        };
      } else {
        return evaluation;
      }
    });
    let evaluationsChanged = this.state.evaluationsChanged;
    evaluationsChanged[indexToChange] = true;

    this.setState({
      evaluations: evaluations,
      evaluationsChanged: evaluationsChanged,
    });
    toast.success("Avaliação editada com sucesso!", {
      className: "toast-success",
    });

    this.handleCourseSave();
  };

  handleCourseSave = async () => {
    const {
      id,
      name,
      description,
      description_short,
      image,
      difficulty,
      duration,
      price,
      points,
      learnings,
      lessons,
      evaluations,
      certification_image_front,
      certification_image_back,
    } = this.state;

    // Validações dos campos obrigatórios para salvar
    if (
      !this.validateRequiredFields(
        name,
        description_short,
        duration,
        difficulty,
        price,
        points,
        lessons,
        evaluations
      )
    ) {
      return;
    }

    let teachers = this.state.teachers.map((item) => item.id);
    let stamps = this.state.stamps.map((item) => item.id);
    let sponsors = this.state.sponsors.map((item) => item.id);
    let requirements = this.state.requirements.map((item) => item.id);
    let categories = this.state.categories.map((item) => item.id);

    let upload = await Api.coursesPanelEditor(
      id,
      name,
      description,
      description_short,
      image,
      difficulty,
      duration,
      price,
      points,
      categories,
      learnings,
      teachers,
      sponsors,
      requirements,
      lessons,
      evaluations,
      stamps,
      certification_image_front,
      certification_image_back
    );

    // Connection error
    upload = upload.courses;
    if (Utils.checkForErrors(this, upload)) {
      return;
    }

    upload = upload.data.panel_editor;
    if (Utils.checkForErrors(this, upload)) {
      return;
    }

    upload = upload.data;
    if (Utils.checkForErrors(this, upload)) {
      return;
    }

    toast.success("Curso salvo com sucesso!", {
      className: "toast-success",
    });
    this.setState({
      lessonsChanged: new Array(this.state.lessons.length).fill(false),
      evaluationsChanged: new Array(2).fill(false),
    });

    if (id && id !== 0) {
      return;
    }
    this.setState({ id: upload.id }, () => {
      this.setState({ redirect: false });
    });
  };

  validateRequiredFields(
    name,
    description_short,
    duration,
    difficulty,
    price,
    points,
    lessons,
    evaluations
  ) {
    let requiredFieldsOK = true;
    if (
      name == "" ||
      description_short == "" ||
      duration == "" ||
      Number(duration) > 99 ||
      difficulty == "" ||
      Number(difficulty) > 5 ||
      price == "" ||
      Number(price) > 99999 ||
      points == "" ||
      Number(points) > 99999 ||
      lessons == null ||
      lessons.length == 0
    ) {
      toast.info(
        "Verifique os campos obrigatórios com (*) para poder salvar a edição do curso.",
        {
          className: "toast-info",
        }
      );

      requiredFieldsOK = false;
    }

    if (lessons != null && lessons.length > 0) {
      lessons.forEach((element) => {
        if (element.name.toLowerCase() == "aula sem título") {
          requiredFieldsOK = false;
          toast.info("Título da aula não informado", {
            className: "toast-info",
          });
        }
      });
    }

    if (evaluations != null && evaluations.length > 0) {
      let evaluationPreOK = true;
      let evaluationPosOK = true;
      evaluations.forEach((item) => {
        if (
          item.name == "" ||
          item.description == "" ||
          item.elements.length == 0
        ) {
          if (item.type == 1) {
            evaluationPreOK = false;
          } else {
            evaluationPosOK = false;
          }
        }

        if (item.elements.length > 0) {
          item.elements.forEach((element) => {
            if (element.question == "" || element.options.length == 0) {
              if (item.type == 1) {
                evaluationPreOK = false;
              } else {
                evaluationPosOK = false;
              }
            }

            if (element.options.length > 0) {
              element.options.forEach((option) => {
                if (option == "") {
                  if (item.type == 1) {
                    evaluationPreOK = false;
                  } else {
                    evaluationPosOK = false;
                  }
                }
              });
            }
          });
        }
      });

      if (!evaluationPreOK || !evaluationPosOK) {
        let msg;
        if (!evaluationPreOK && !evaluationPosOK) {
          msg = "pré e pós";
        } else if (!evaluationPreOK) {
          msg = "pré";
        } else {
          msg = "pós";
        }

        toast.info(
          `Finalize a ${msg} avaliação iniciada para poder salvar a edição do curso.`,
          {
            className: "toast-info",
          }
        );

        requiredFieldsOK = false;
      }
    }

    return requiredFieldsOK;
  }

  renderLearningsItemsView = () => {
    const list = this.state.learnings.map((item, index) => {
      let text = Utils.capitalizeFirstLetter(item);
      return (
        <li key={index} className="list-item">
          {text}
          <FontAwesomeIcon
            onClick={() => this.handleLearningRemove(index)}
            icon={"times"}
          />
        </li>
      );
    });

    return list;
  };

  renderLessonsItemsView = () => {
    const list = this.state.lessons.map((item, index) => {
      return (
        <LessonItem
          onLessonEdit={this.handleLessonEdit}
          onLessonDelete={this.handleLessonDelete}
          key={index}
          item={item}
          index={index}
          hasChanged={this.state.lessonsChanged[index]}
        />
      );
    });

    return list;
  };

  renderEvaluationsItemsView = () => {
    let preEvaluation = null;
    let postEvaluation = null;

    if (this.state.evaluations.length > 0) {
      if (this.state.evaluations[0].type === 1) {
        preEvaluation = this.state.evaluations[0];
      } else {
        postEvaluation = this.state.evaluations[0];
      }
    }

    if (this.state.evaluations.length > 1) {
      if (this.state.evaluations[1].type === 1) {
        preEvaluation = this.state.evaluations[1];
      } else {
        postEvaluation = this.state.evaluations[1];
      }
    }

    return (
      <div className="evaluation-list">
        {/* Pre */}
        <div
          className={`evaluation-item-container ${
            preEvaluation !== null ? "animated" : ""
          }`}
          style={{ width: "49%" }}
        >
          {this.renderPreEvaluation(preEvaluation)}
        </div>
        {/* Post */}
        <div
          className={`evaluation-item-container ${
            postEvaluation !== null ? "animated" : ""
          }`}
          style={{ width: "49%" }}
        >
          {this.renderPostEvaluation(postEvaluation)}
        </div>
      </div>
    );
  };

  renderPreEvaluation(preEvaluation) {
    if (preEvaluation === null) {
      return (
        <Button
          type="orange"
          title="Adicionar Pré-Avaliação"
          onClick={() => {
            let newEvaluation = {
              id: 0,
              type: 1,
              name: "",
              description: "",
              elements: [],
            };

            this.setState({
              evaluations: [...this.state.evaluations, newEvaluation],
            });
          }}
        />
      );
    }

    return (
      <EvaluationItem
        label="PRÉ"
        item={preEvaluation}
        index={0}
        onEvaluationEdit={this.handleEvaluationEdit}
        onEvaluationDelete={this.handleEvaluationDelete}
        hasChanged={this.state.evaluationsChanged[0]}
      />
    );
  }

  renderPostEvaluation(postEvaluation) {
    if (postEvaluation === null) {
      return (
        <Button
          type="orange"
          title="Adicionar Pós-Avaliação"
          onClick={() => {
            let newEvaluation = {
              id: 0,
              type: 2,
              name: "",
              description: "",
              elements: [],
            };

            this.setState({
              evaluations: [...this.state.evaluations, newEvaluation],
            });
          }}
        />
      );
    }

    return (
      <EvaluationItem
        label="PÓS"
        item={postEvaluation}
        index={1}
        onEvaluationEdit={this.handleEvaluationEdit}
        onEvaluationDelete={this.handleEvaluationDelete}
        hasChanged={this.state.evaluationsChanged[1]}
      />
    );
  }

  renderRedirect() {
    // /editor?id=${this.state.id}
    if (this.state.redirect) {
      return (
        <Redirect
          to={{ pathname: `/cursos`, state: { from: this.props.location } }}
        />
      );
    }
  }
}

export default ScreenCourseEdit;
