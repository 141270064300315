import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/icon_mri.png";

const HeaderItem = (props) => {
  if (props.title) {
    return (
      <NavLink
        to={props.path}
        title={props.title}
        activeStyle={{ fontWeight: "bold", textDecoration: "underline" }}
        className="header-item"
        exact
      >
        {props.title}
      </NavLink>
    );
  }

  return (
    <NavLink
      to={props.path}
      activeStyle={{ fontWeight: "bold", textDecoration: "underline" }}
      className="header-item"
      exact
    >
      <img src={logo} alt={props.alt} />
    </NavLink>
  );
};

export default HeaderItem;
