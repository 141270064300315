import React from "react";
import Api from "../apis/api";
import Utils from "../components/Utils";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "../components/common/Button";
import PlanItem from "../components/js/PlanItem";
import InputField from "../components/inputs/InputField";
import {
  ChevronFirst,
  ChevronLast,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import DiscountInput from "../components/inputs/DiscountInput";

class ScreenPlansList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showCreate: false,
      plans: [],
      pagination: {
        page: 1,
        pages: 1,
        pageSize: 50,
        total: 0,
      },
      inputPrice: "",
      inputSpinsCount: "",
      inputDiscount: "",
      inputSaleValue: "0",
      error: 0,
    };
  }

  componentDidMount() {
    this.fetchData(1, this.state.pagination.pageSize);
  }

  fetchData(page, apiPageSize) {
    this.setState({ loading: true, plans: [] }, async () => {
      try {
        let response = await Api.plansPanelList(page, apiPageSize);
        console.log("dados do response", response);
        let listWrapper = response.plans.data.list;
        if (Utils.checkForErrors(this, listWrapper)) {
          this.setState({ loading: false });
          return;
        }
        let paginationData = listWrapper.data;
        if (Utils.checkForErrors(this, paginationData)) {
          this.setState({ loading: false });
          return;
        }
        const totalPlans = paginationData.total;
        const plansList = paginationData.list || [];
        const displayPageSize = 10;
        const totalPages = Math.ceil(totalPlans / displayPageSize);

        this.setState({
          plans: plansList,
          pagination: {
            page: paginationData.page,
            pages: totalPages,
            pageSize: displayPageSize,
            total: totalPlans,
          },
          loading: false,
        });
      } catch (error) {
        console.error("❌ Erro ao buscar planos:", error);
        this.setState({ loading: false });
      }
    });
  }

  renderList() {
    const { plans, pagination } = this.state;
    const { page, pageSize } = pagination;

    if (plans.length === 0) {
      return (
        <p style={{ fontFamily: "Averta", fontSize: "1.2rem" }}>
          Não há planos cadastrados!
        </p>
      );
    }

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentPlans = plans.slice(startIndex, endIndex);

    return currentPlans.map((plan, index) => (
      <PlanItem
        key={plan.id}
        item={plan}
        index={startIndex + index}
        onPlanDelete={this.handlePlanDelete}
        onSaveChanges={this.saveChanges}
        onEdit={() => this.handleShowEdit(plan)}
      />
    ));
  }

  handlePreviousPage = () => {
    this.setState((prevState) => {
      const newPage =
        prevState.pagination.page > 1 ? prevState.pagination.page - 1 : 1;
      return {
        pagination: { ...prevState.pagination, page: newPage },
      };
    });
  };

  handleNextPage = () => {
    this.setState((prevState) => {
      const newPage =
        prevState.pagination.page < prevState.pagination.pages
          ? prevState.pagination.page + 1
          : prevState.pagination.page;
      return {
        pagination: { ...prevState.pagination, page: newPage },
      };
    });
  };

  handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    const total = this.state.pagination.total;
    const newPages = Math.ceil(total / newSize);
    this.setState((prevState) => ({
      pagination: {
        ...prevState.pagination,
        pageSize: newSize,
        pages: newPages,
        page: 1,
      },
    }));
  };

  renderPagination() {
    const { page, pages, pageSize, total } = this.state.pagination;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginTop: "20px",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "30px" }}
        >
          <span>Linhas por página: </span>
          <select
            value={pageSize}
            onChange={this.handlePageSizeChange}
            style={{ margin: "0 10px" }}
          >
            {[10, 15, 20, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div style={{ marginRight: "30px" }}>
          <span>
            {(page - 1) * pageSize + 1} - {Math.min(page * pageSize, total)} de{" "}
            {total}
          </span>
        </div>
        <button
          onClick={() =>
            this.setState((prevState) => ({
              pagination: { ...prevState.pagination, page: 1 },
            }))
          }
          disabled={page === 1}
          style={{
            marginLeft: "10px",
            background: "none",
            border: "none",
            cursor: page === 1 ? "default" : "pointer",
          }}
        >
          <ChevronFirst
            style={{ fontSize: "20px", color: page === 1 ? "#ACACAC" : "#000" }}
          />
        </button>
        <button
          onClick={this.handlePreviousPage}
          disabled={page === 1}
          style={{
            marginLeft: "5px",
            background: "none",
            border: "none",
            cursor: page === 1 ? "default" : "pointer",
          }}
        >
          <ChevronLeft
            style={{ fontSize: "20px", color: page === 1 ? "#ACACAC" : "#000" }}
          />
        </button>
        <button
          onClick={this.handleNextPage}
          disabled={page === pages}
          style={{
            marginLeft: "5px",
            background: "none",
            border: "none",
            cursor: page === pages ? "default" : "pointer",
          }}
        >
          <ChevronRight
            style={{
              fontSize: "20px",
              color: page === pages ? "#ACACAC" : "#000",
            }}
          />
        </button>
        <button
          onClick={() =>
            this.setState((prevState) => ({
              pagination: { ...prevState.pagination, page: pages },
            }))
          }
          disabled={page === pages}
          style={{
            marginLeft: "5px",
            background: "none",
            border: "none",
            cursor: page === pages ? "default" : "pointer",
          }}
        >
          <ChevronLast
            style={{
              fontSize: "20px",
              color: page === pages ? "#ACACAC" : "#000",
            }}
          />
        </button>
      </div>
    );
  }

  // Métodos de cálculos, criação, edição e exclusão permanecem inalterados

  updateCalculations = () => {
    let price =
      parseFloat(this.state.inputPrice.toString().replace(",", ".")) || 0;
    let spinsCount = parseInt(this.state.inputSpinsCount) || 0;
    let discountPercent =
      parseFloat(this.state.inputDiscount.toString().replace(",", ".")) || 0;

    if (price <= 0 || spinsCount <= 0) {
      this.setState({
        inputDiscountValue: "",
        inputCalculatedValue: "",
        inputSpinValue: "",
        inputSaleValue: "",
      });
      return;
    }

    let discountValue = (price * discountPercent) / 100;
    let calculatedValue = price - discountValue;
    let spinValue =
      spinsCount > 0 ? (calculatedValue / spinsCount).toFixed(2) : 0;
    let saleValue = calculatedValue;

    this.setState({
      inputDiscountValue: discountValue.toFixed(2),
      inputCalculatedValue: calculatedValue.toFixed(2),
      inputSpinValue: spinValue,
      inputSaleValue: saleValue.toFixed(2),
    });
  };

  handleCloseCreate = () => {
    this.setState({ showCreate: false });
  };

  handleShowCreate = () => {
    this.setState({ showCreate: true });
  };

  handlePlanDelete = async (id) => {
    let del = await Api.planPanelDelete(id);
    del = del.plans;
    if (Utils.checkForErrors(this, del)) return;
    del = del.data.panel_rem;
    if (Utils.checkForErrors(this, del)) return;

    if (del.data) {
      toast.success("Plano deletado com sucesso!", {
        className: "toast-success",
      });
      // Recarrega os dados com a chamada à API usando pageSize 50
      this.fetchData(1, 50);
    }
  };

  handleSave = () => {
    this.updateCalculations();
    let price = Number(this.state.inputPrice.toString().replace(",", "."));
    let spinsCount = this.state.inputSpinsCount;
    let discount = Number(
      this.state.inputDiscount.toString().replace(",", ".")
    );
    this.saveChanges("", price, spinsCount, discount);
  };

  saveChanges = async (id, price, spinsCount, discount, salePriceParam) => {
    price = Number(price.toString().replace(",", "."));
    discount = Number(discount.toString().replace(",", "."));

    if (!this.validateRequiredFields(price, spinsCount, discount)) {
      return;
    }

    const salePrice =
      salePriceParam !== undefined
        ? Number(
            parseFloat(salePriceParam.toString().replace(/,/g, ".")).toFixed(2)
          )
        : Number(
            parseFloat(
              (this.state.inputSaleValue || "0").toString().replace(/,/g, ".")
            ).toFixed(2)
          );

    let upload = await Api.planPanelEditor(
      id,
      price,
      spinsCount,
      discount,
      salePrice
    );
    upload = upload.plans;
    if (Utils.checkForErrors(this, upload)) return;
    upload = upload.data.panel_editor;
    if (Utils.checkForErrors(this, upload)) return;
    upload = upload.data;
    if (Utils.checkForErrors(this, upload)) return;

    toast.success(
      id === "" ? "Plano criado com sucesso!" : "Plano atualizado com sucesso!",
      {
        className: "toast-success",
      }
    );

    this.setState(
      {
        inputPrice: "",
        inputSpinsCount: "",
        inputDiscount: "",
        inputSaleValue: "",
      },
      () => {
        this.setState({ showCreate: false });
        this.fetchData(1, 50);
      }
    );
  };

  validateRequiredFields(price, spinsCount, discount) {
    let requiredFieldsOK = true;
    if (
      price === "" ||
      price === 0 ||
      spinsCount === "" ||
      spinsCount === 0 ||
      isNaN(price) ||
      isNaN(spinsCount) ||
      Number(price) > 99999 ||
      Number(spinsCount) > 99999 ||
      isNaN(discount)
    ) {
      toast.info(
        "Verifique os campos obrigatórios com (*) para poder salvar a edição do plano.",
        {
          className: "toast-info",
        }
      );
      requiredFieldsOK = false;
    }
    return requiredFieldsOK;
  }

  render() {
    if (this.state.error !== 0) {
      return null;
    }
    return (
      <div
        className="main-content"
        style={{
          textAlign: "center",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
      >
        <div
          className="content-header fluid centered flex-between min-600"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "2rem",
            paddingTop: "1rem",
          }}
        >
          <span
            style={{ fontSize: "2rem", fontWeight: "bold", color: "#FF8F18" }}
          >
            Planos
          </span>
          <Button
            onClick={this.handleShowCreate}
            type="orange"
            title="Adicionar novo plano"
          />
        </div>

        <Modal
          show={this.state.showCreate}
          onHide={this.handleCloseCreate}
          backdrop="static"
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title>Adicionar Plano</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "16px",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "1" }}>
                <InputField
                  label="Quantidade (*)"
                  value={this.state.inputSpinsCount}
                  onChange={(e) =>
                    this.setState(
                      { inputSpinsCount: e.target.value },
                      this.updateCalculations
                    )
                  }
                  placeholder="Digite a quantidade..."
                />
              </div>
              <div style={{ flex: "1" }}>
                <InputField
                  label="Valor base (*)"
                  value={this.state.inputPrice}
                  onChange={(e) =>
                    this.setState(
                      { inputPrice: e.target.value },
                      this.updateCalculations
                    )
                  }
                  placeholder="Digite o valor..."
                />
              </div>
              <div style={{ flex: "1" }}>
                <DiscountInput
                  label="Desconto (%)"
                  value={this.state.inputDiscount}
                  onChange={(e) =>
                    this.setState(
                      { inputDiscount: e.target.value },
                      this.updateCalculations
                    )
                  }
                  placeholder="Digite o desconto em %..."
                  tag={"%"}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "16px",
                flexWrap: "nowrap",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <div style={{ flex: "1" }}>
                <DiscountInput
                  label="Desconto (R$)"
                  value={this.state.inputDiscountValue}
                  readOnly
                  tag={"R$"}
                />
              </div>
              <div style={{ flex: "1" }}>
                <InputField
                  label="Valor calculado"
                  value={this.state.inputCalculatedValue}
                  readOnly
                />
              </div>
              <div style={{ flex: "1" }}>
                <InputField
                  label="Valor un. Spin"
                  value={this.state.inputSpinValue}
                  readOnly
                />
              </div>
            </div>

            <div style={{ flex: "1", marginTop: "14px" }}>
              <InputField
                label="Valor de venda"
                value={this.state.inputSaleValue}
                readOnly
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.handleSave} type="orange" title="Salvar" />
          </Modal.Footer>
        </Modal>

        <div id="plans-list">
          <table
            style={{
              width: "100%",
              backgroundColor: "#fff",
              marginTop: "2rem",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              border: "1px solid #eae4e4",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <tbody style={{ marginLeft: "10rem", marginRight: "10rem" }}>
              <tr style={{ textAlign: "left", fontSize: "14px" }}>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Quantidade</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Valor base</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Desconto %</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Desconto R$</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Valor calculado</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Valor un. Spin</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Valor de venda</h3>
                </td>
                <td style={{ width: "10%" }}>
                  <h3 className="name">Ações</h3>
                </td>
              </tr>
            </tbody>
          </table>
          {this.renderList()}
        </div>

        <div id="plans-list">{this.renderPagination()}</div>
      </div>
    );
  }
}

export default ScreenPlansList;
