import React from "react";

const DiscountInput = ({ value, onChange, tag }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <label
        style={{
          color: "#272727",
          fontSize: "16px",
          fontWeight: "500",
          marginBottom: "4px",
        }}
      >
        Desconto
      </label>
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <input
          type="text"
          value={value}
          onChange={onChange}
          placeholder="Digite aqui"
          style={{
            width: "100%",
            height: "40px",
            padding: "10px 12px",
            paddingRight: "50px",
            border: "1px solid #D1D5DB",
            borderRadius: "15px",
            backgroundColor: "#FAFAFA",
            color: "#374151",
            fontSize: "14px",
            outline: "none",
            transition: "border-color 0.2s ease-in-out",
          }}
        />
        <span
          style={{
            position: "absolute",
            right: "0",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 12px",
            backgroundColor: "#F97316",
            color: "#FFFFFF",
            fontWeight: "bold",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        >
          {tag}
        </span>
      </div>
    </div>
  );
};

export default DiscountInput;
