import React from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";

import Button from "../common/Button";

class CoursePanelListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDelete: false,
      showPublish: false,
      link: "",

      id: "",
      name: "",
      description_short: "",
      published: false,
    };
  }

  componentDidMount() {
    const { id, name, description_short, published } = this.props.item;

    this.setState({
      id,
      name,
      description_short: description_short,
      link: `/cursos/editor?id=${this.props.item.id}`,
      published,
    });
  }

  render() {
    return (
      <div className="course-itemList">
        <h3 className="course-title">{this.state.name}</h3>
        <div className="course-info">
          <div className="course-description">
            {this.state.description_short}
          </div>
          <div className="course-actions">
            <Button
              onClick={this.handleShowPublish}
              style={{ width: "140px" }}
              icon={this.state.published ? "arrow-down" : "arrow-up"}
              type="orange"
              title={this.state.published ? "Despublicar" : "Publicar"}
            />
            <Link
              style={{ width: "120px" }}
              className="button orange-bg"
              to={this.state.link}
              title="Editar"
            >
              <FontAwesomeIcon icon="edit" />
              Editar
            </Link>
            <Button
              onClick={this.handleShowDelete}
              style={{ width: "120px" }}
              icon="trash-alt"
              type="orange"
              title="Deletar"
            />

            <Modal
              show={this.state.showDelete}
              onHide={this.handleCloseDelete}
              backdrop="static"
              className="modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="modal-title">Deletar Curso</Modal.Title>
              </Modal.Header>

              <Modal.Body style={{ minHeight: "100px", textAlign: "center" }}>
                <h3>
                  O curso deletado não poderá ser recuperado. Você deseja
                  continuar?
                </h3>
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={this.handleDelete} type="orange" title="Sim" />
                <Button onClick={this.handleCloseDelete} title="Não" />
              </Modal.Footer>
            </Modal>

            <Modal
              show={this.state.showPublish}
              onHide={this.handleClosePublish}
              backdrop="static"
              className="modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="modal-title">
                  {this.state.published
                    ? "Despublicar Curso"
                    : "Publicar Curso"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body style={{ minHeight: "100px", textAlign: "center" }}>
                <h3>Você deseja alterar a situação do curso?</h3>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  onClick={this.handlePublish}
                  type="orange"
                  title="Sim"
                />
                <Button onClick={this.handleClosePublish} title="Não" />
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }

  // Handlers
  handleCloseDelete = () => {
    this.setState({ showDelete: false });
  };

  handleShowDelete = () => {
    this.setState({ showDelete: true });
  };

  handleDelete = () => {
    this.props.onCourseDelete(this.state.id);
    this.handleCloseDelete();
  };

  // Handlers
  handleClosePublish = () => {
    this.setState({ showPublish: false });
  };

  handleShowPublish = () => {
    this.setState({ showPublish: true });
  };

  handlePublish = () => {
    this.props.onCoursePublish(this.state.id, this.state.published);
    this.setState({ published: !this.state.published });
    this.handleClosePublish();
  };
}

export default CoursePanelListItem;
