import "../css/app.css";
import React from "react";
import Api from "../../apis/api";
import Utils from "../Utils";
import { uploadFile } from "react-s3";
import { API_ROOT } from "../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";
import Button from "../common/Button";
import TextareaAutosize from "react-autosize-textarea";
import iconeSpin from "../../assets/images/iconWhite.png";

import { toast } from "react-toastify";
import { HiPencilSquare } from "react-icons/hi2";

class UserItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddCredit: false,
      showCoursesModal: false,
      showEditUser: false,
      editName: "",
      editLastname: "",
      editPhoneNumber: "",
      id: "",
      name: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      credits: "",
      inputCredits: "",
      courses: [],
    };
  }

  componentDidMount = () => {
    const { item } = this.props;
    this.setState({
      id: item.id,
      name: item.name || "N/A",
      lastname: item.lastname || "N/A",
      email: item.email || "N/A",
      phoneNumber: item.phone_number || "N/A",
      credits: item.credits || "N/A",
    });
  };

  fetchCourses = async () => {
    const { item } = this.props;

    try {
      const response = await Api.coursesListUser(item.id);

      if (
        response.courses &&
        response.courses.data &&
        response.courses.data.list_courses_of_user &&
        response.courses.data.list_courses_of_user.data &&
        response.courses.data.list_courses_of_user.data.list
      ) {
        this.setState({
          courses: response.courses.data.list_courses_of_user.data.list,
        });
      } else {
        console.warn("Estrutura de resposta inesperada:", response);
      }
    } catch (error) {
      console.error("Erro ao buscar cursos:", error);
      toast.error("Erro ao carregar cursos.");
    }
  };

  handleShowCourses = () => {
    this.setState({ showCoursesModal: true }, this.fetchCourses);
  };

  handleShowEdit = () => {
    this.setState({
      showEditUser: true,
      editName: this.state.name,
      editLastname: this.state.lastname,
      editPhoneNumber: this.state.phoneNumber,
    });
  };

  handleCloseEdit = () => {
    this.setState({ showEditUser: false });
  };

  handleSaveEdit = async () => {
    const { editName, editLastname, email, editPhoneNumber } = this.state;

    if (!editName || !editLastname || !editPhoneNumber) {
      toast.info("Por favor, preencha todos os campos.");
      return;
    }

    try {
      const response = await Api.updateUser(
        editName,
        editLastname,
        email,
        editPhoneNumber
      );
      this.setState({
        name: editName,
        lastname: editLastname,
        phoneNumber: editPhoneNumber,
        showEditUser: false,
      });
      toast.success("Usuário atualizado com sucesso.");
    } catch (error) {
      console.error("Erro ao atualizar usuário", error);
      toast.error("Erro ao atualizar usuário.");
    }
  };

  handleShowAdd = () => {
    this.setState({ showAddCredit: true });
  };

  handleCloseAdd = () => {
    this.setState({
      showAddCredit: false,
    });
  };

  handleAddCredit = () => {
    try {
      this.setState({ showAddCredit: true });

      let credits = this.state.inputCredits;

      if (!this.validateRequiredFields(credits)) {
        return;
      }

      this.setState({ showAddCredit: false });

      this.props.onSaveChanges(credits, this.state.id);
      this.handleCloseAdd();
      toast.success("Créditos adicionados com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar créditos:", error);
      toast.error("Ocorreu um erro ao adicionar créditos. Tente novamente.");
    }
  };

  validateRequiredFields(value) {
    let requiredFieldsOK = true;
    if (value === "" || value == 0 || isNaN(value) || Number(value) > 99999) {
      toast.info(
        "Verifique o campo obrigatório com (*) para poder salvar a adição dos créditos.",
        {
          className: "toast-info",
        }
      );

      requiredFieldsOK = false;
    }

    return requiredFieldsOK;
  }

  render() {
    const {
      name,
      lastname,
      email,
      phoneNumber,
      credits,
      showCoursesModal,
      courses,
      showEditUser,
      editName,
      editLastname,
      editPhoneNumber,
    } = this.state;
    const { index } = this.props;

    return (
      <div style={{ width: "100%" }}>
        <table
          style={{
            width: "100%",
            backgroundColor: "#fff",
            paddingLeft: "20px",
            paddingRight: "20px",
            border: "1px solid #eae4e4",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: index % 2 === 0 ? "#fff" : "#F9F9F9",
          }}
        >
          <tbody style={{ textAlign: "left" }}>
            <tr>
              <td style={{ width: "10%" }}>
                <p className="name">{name + " " + lastname}</p>
              </td>
              <td style={{ width: "18%" }}>
                <p className="name">{email}</p>
              </td>
              <td style={{ width: "10%" }}>
                <p className="name">{phoneNumber}</p>
              </td>
              <td style={{ width: "10%" }}>
                <p className="name">{credits}</p>
              </td>
              <td
                style={{
                  width: "10%",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                <div className="actions">
                  <button
                    onClick={this.handleShowAdd}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0px",
                      backgroundColor: "#FF8127",
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "14px",
                      borderRadius: "9999px",
                      border: "none",
                      cursor: "pointer",
                      outline: "none",
                      height: "35px",
                      paddingLeft: "5px",
                      paddingRight: "15px",
                    }}
                  >
                    <img src={iconeSpin} alt="Spin" width={40} />
                    Adicionar spins
                  </button>
                </div>
              </td>

              <td style={{ width: "10%" }}>
                <div
                  className="actions"
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div
                    className="edit-icon"
                    style={{ cursor: "pointer" }}
                    onClick={this.handleShowEdit}
                  >
                    <HiPencilSquare
                      style={{ fontSize: "24px" }}
                      color="#ACACAC"
                      icon={"edit"}
                    />
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                      padding: "4px 20px",
                      border: "2px solid #B65A38",
                      borderRadius: "9999px",
                      color: "#B65A38",
                      fontWeight: "bold",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={this.handleShowCourses}
                  >
                    Cursos
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {/* Modal de Cursos */}
        <Modal
          show={showCoursesModal}
          onHide={() => this.setState({ showCoursesModal: false })}
          backdrop="static"
          className="modal"
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title className="modal-title">
              Cursos cadastrados
              <p style={{ color: "#B65A38", fontSize: "14px" }}>
                {name} {lastname}
              </p>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              style={{
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {courses.length > 0 ? (
                courses.map((course, index) => (
                  <div
                    key={index}
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    {course.name}
                  </div>
                ))
              ) : (
                <p style={{ textAlign: "center", fontWeight: "bold" }}>
                  Nenhum curso encontrado.
                </p>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal de Adicionar Crédito */}
        <Modal
          show={this.state.showAddCredit}
          onHide={this.handleCloseAdd}
          backdrop="static"
          className="modal"
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title className="modal-title">Adicionar Crédito</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="">
              <div className="modal-input-group modal-input-name">
                <label>
                  Nome: {this.state.name + " " + this.state.lastname}
                </label>
              </div>
              <div
                className="modal-input-group modal-input-name"
                style={{ paddingTop: 40 }}
              >
                <label>Crédito atual (spins): {this.state.credits}</label>
              </div>
              <div
                className="modal-input-group modal-input-name"
                style={{ paddingTop: 25 }}
              >
                <label>Spins(*)</label>
                <input
                  type="text"
                  maxLength={5}
                  value={this.state.inputCredits}
                  onChange={(e) =>
                    this.setState({ inputCredits: e.target.value })
                  }
                  placeholder="Digite aqui a quantidade de spins..."
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={this.handleAddCredit}
              type="orange"
              title="Salvar"
            />
          </Modal.Footer>
        </Modal>

        {/* Modal de Edição de Usuário */}
        <Modal
          show={showEditUser}
          onHide={this.handleCloseEdit}
          backdrop="static"
          className="modal"
        >
          <Modal.Header closeButton closeLabel="">
            <Modal.Title className="modal-title">Editar Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-edit-user">
              <div className="modal-input-group">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label
                    htmlFor="buscar-nome"
                    style={{
                      color: "#FF8F18",
                      fontWeight: "500",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Nome
                  </label>
                  <input
                    type="text"
                    value={editName}
                    onChange={(e) =>
                      this.setState({ editName: e.target.value })
                    }
                    placeholder="Digite o nome"
                    style={{
                      padding: "0.5rem",
                      paddingRight: "30px",
                      borderRadius: "15px",
                      border: "1px solid #E3E3E3",
                      marginRight: "20px",
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#FAFAFA",
                      fontSize: "14px",
                    }}
                  />
                </div>
              </div>

              <div
                className="modal-input-group"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <label
                  htmlFor="buscar-nome"
                  style={{
                    color: "#FF8F18",
                    fontWeight: "500",
                    marginBottom: "0.5rem",
                  }}
                >
                  Sobrenome
                </label>
                <input
                  type="text"
                  value={editLastname}
                  onChange={(e) =>
                    this.setState({ editLastname: e.target.value })
                  }
                  placeholder="Digite o sobrenome"
                  style={{
                    padding: "0.5rem",
                    paddingRight: "30px",
                    borderRadius: "15px",
                    border: "1px solid #E3E3E3",
                    marginRight: "20px",
                    width: "100%",
                    height: "40px",
                    backgroundColor: "#FAFAFA",
                    fontSize: "14px",
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "20px", width: "100%" }}>
                {/* Input de Email (Desabilitado) */}
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="email"
                    style={{
                      color: "#FF8F18",
                      fontWeight: "500",
                      marginBottom: "0.5rem",
                      display: "block",
                    }}
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    value={email}
                    disabled
                    placeholder="Email não pode ser alterado"
                    style={{
                      padding: "0.5rem",
                      paddingRight: "30px",
                      borderRadius: "15px",
                      border: "1px solid #E3E3E3",
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#FAFAFA",
                      cursor: "not-allowed",
                      opacity: 0.6,
                      fontSize: "14px",
                    }}
                  />
                </div>

                {/* Input de Telefone */}
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="telefone"
                    style={{
                      color: "#FF8F18",
                      fontWeight: "500",
                      marginBottom: "0.5rem",
                      display: "block",
                    }}
                  >
                    Telefone
                  </label>
                  <input
                    type="text"
                    value={editPhoneNumber}
                    onChange={(e) =>
                      this.setState({ editPhoneNumber: e.target.value })
                    }
                    placeholder="Digite o telefone"
                    style={{
                      padding: "0.5rem",
                      paddingRight: "30px",
                      borderRadius: "15px",
                      border: "1px solid #E3E3E3",
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#FAFAFA",
                      fontSize: "14px",
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.handleSaveEdit}
              type="orange"
              title="Salvar"
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default UserItem;
