import React from "react";

const Image = ({ src }) => (src ? <img src={src} alt="" /> : null);

const Video = ({ src }) => {
  if (!src) return null;

  // Regex para YouTube
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const youtubeMatch = src.match(youtubeRegex);

  if (youtubeMatch) {
    return (
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${youtubeMatch[1]}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  }

  // Regex para Vimeo
  const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)(\d+)/;
  const vimeoMatch = src.match(vimeoRegex);

  if (vimeoMatch) {
    return (
      <iframe
        width="560"
        height="315"
        src={`https://player.vimeo.com/video/${vimeoMatch[1]}`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  }

  return (
    <video controls src={src}>
      Seu navegador não suporta o player de vídeo.
    </video>
  );
};

export const Media = ({ block, contentState }) => {
  const entityKey = block.getEntityAt(0);
  if (!entityKey) {
    return null;
  }
  const entity = contentState.getEntity(entityKey);
  const { src } = entity.getData();
  const type = entity.getType();

  if (type === "image") {
    return <Image src={src} />;
  } else if (type === "video" || type === "youtube") {
    return <Video src={src} />;
  }
  return null;
};

export const mediaBlockRenderer = (block) => {
  if (
    block.getType() === "atomic-image" ||
    block.getType() === "atomic-video" ||
    block.getType() === "atomic-youtube"
  ) {
    return {
      component: Media,
      editable: false,
    };
  }
};
